<template>
  <div class="main order" id="main">
    <div class="btn_top">
      <el-form :inline="true">
        <el-form-item>
          <el-date-picker
            v-model="date"
            type="daterange"
            range-separator="至"
            start-placeholder="创建开始时间"
            end-placeholder="创建结束时间"
            unlink-panels
            value-format="yyyy-MM-dd"
          />
        </el-form-item>

        <el-form-item>
          <el-select
            v-model="params.tagBizType"
            placeholder="请选择标签类型"
            clearable
            style="width: 200px"
          >
            <el-option label="自定义模型标签" value="CUSTOM" />
            <el-option label="动态标签" value="CALCULATE" />
            <el-option label="RFM标签" value="RFM" />
            <el-option label="手动标签" value="MANUAL" />
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-select
            v-model="params.status"
            placeholder="请选择标签状态"
            clearable
            style="width: 200px"
          >
            <el-option label="启用" :value="0" />
            <el-option label="禁用" :value="1" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="addLabel()"
            v-permission="['labelManagement:add']"
            >创建标签</el-button
          >
        </el-form-item>
        <el-form-item>
          <filter-column :columns.sync="columns" @refresh="getList" />
        </el-form-item>
      </el-form>
      <div>
        <!-- <el-button type="primary" @click="allUpdate">全部更新</el-button> -->
        <el-button type="primary" @click="importUser">导入用户</el-button>
      </div>
    </div>

    <div class="content_box">
      <div class="group_box">
        <el-radio-group
          v-model="tagType"
          size="medium"
          style="width: 100%"
          @change="getTagGroup"
        >
          <el-radio-button :label="1" size="medium"
            ><div class="tagTypeStyle">系统标签</div></el-radio-button
          >
          <el-radio-button :label="0" size="medium"
            ><div class="tagTypeStyle">自定义标签</div></el-radio-button
          >
        </el-radio-group>
        <div class="list_view">
          <div class="add_box" @click="addGroup(null, 1)" v-if="tagType == 0">
            <i class="el-icon-plus"></i>
            <span>添加分组</span>
          </div>
          <div class="list_box">
            <el-tree
              :data="groupList"
              node-key="id"
              default-expand-all
              @node-drag-end="handleDragEnd"
              @node-drop="handleDrop"
              @node-click="treeNodeClick"
              draggable
              :allow-drop="allowDrop"
              :allow-drag="allowDrag"
              :props="{
                label: 'name',
              }"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>{{ node.label }}</span>
                <span v-if="data.id != '' && data.isPreset != 0">
                  <el-button
                    type="text"
                    size="mini"
                    @click="addGroup(data, 2)"
                    v-if="tagType != 1"
                  >
                    <i class="el-icon-edit"></i>
                  </el-button>
                  <el-button
                    type="text"
                    size="mini"
                    @click="addGroup(data, 1)"
                    v-if="tagType != 1 ? node.level != 3 : false"
                  >
                    <i class="el-icon-circle-plus-outline"></i>
                  </el-button>
                  <el-button
                    type="text"
                    size="mini"
                    @click="deleteGroup(data)"
                    v-if="tagType != 1"
                  >
                    <i class="el-icon-delete"></i>
                  </el-button>
                </span>
              </span>
            </el-tree>
          </div>
        </div>
      </div>
      <div class="table_box">
        <el-s-table :columns="columns" :data="tableData" border>
          <template v-slot:status="{ row }">
            <div class="status">
              <span :class="row.status == 1 ? 'waring' : ''"></span>
              {{ !row.status ? "启用" : "禁用" }}
            </div>
          </template>
          <template v-slot:default="{ row }">
            <el-button
              type="text"
              @click="synchronization(row)"
              v-permission="['labelManagement:update']"
              >同步至企微标签</el-button
            >
            <el-button type="text" @click="upDateTag(row)"> 更新 </el-button>
            <el-button type="text" @click="copyTag(row)" v-if="tagType == 0">
              复制
            </el-button>
            <el-button
              type="text"
              @click="editLabel(row)"
              v-if="
                [null, undefined].includes(row.refCount) || row.refCount <= 0
              "
              v-permission="['labelManagement:edit']"
              >编辑</el-button
            >

            <el-dropdown
              style="margin-left: 10px"
              trigger="click"
              v-permission="[
                'labelManagement:open',
                'labelManagement:detail',
                'labelManagement:del',
              ]"
            >
              <el-button type="text"
                >更多<i class="el-icon-arrow-down el-icon--right"></i
              ></el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <el-button
                    type="text"
                    @click="changeStatus(row)"
                    v-permission="['labelManagement:open']"
                    >{{ row.status == 0 ? "禁用" : "启用" }}</el-button
                  >
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button
                    type="text"
                    @click="labelDetail(row)"
                    v-permission="['labelManagement:detail']"
                    >详情</el-button
                  >
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button
                    type="text"
                    @click="labelDel(row)"
                    v-if="tagType == 0"
                    v-permission="['labelManagement:del']"
                    >删除</el-button
                  >
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-s-table>
        <div class="pagebox">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="params.page"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="params.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total"
            background
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <el-dialog
      :title="type == 1 ? '添加分组' : '编辑分组'"
      width="360px"
      :visible.sync="isshowAdd"
      :before-close="handelClose"
      :close-on-click-modal="false"
    >
      <el-form ref="ruleFormRef" :model="ruleForm">
        <el-form-item>
          <el-input
            v-model="ruleForm.groupName"
            placeholder="请输入标签分组，15个字符内"
            maxlength="15"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer">
        <el-button @click="handelClose">取 消</el-button>
        <el-button type="primary" @click="submitGroup">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="同步至企微标签"
      :visible.sync="sycWechatTagDialog"
      width="800px"
      top="10vh"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="
        sycWechatTagParam.corpIdList = null;
        sycWechatTagParam.wechatUserList = null;
        sycWechatTagParam.wechatUserType = 2;
        sendUserList = [];
        sycWechatTagDialog = false;
      "
    >
      <div>
        <el-form
          ref="sycWechatTagRefs"
          :model="sycWechatTagParam"
          :rules="sycWechatTagRule"
        >
          <el-form-item label="企微成员" prop="wechatUserType">
            <el-radio-group
              v-model="sycWechatTagParam.wechatUserType"
              @change="sycWechatTagParam.wechatUserList = null"
            >
              <el-radio :label="1">所有主体成员</el-radio>
              <el-radio :label="2">
                <span>自定义成员</span>
              </el-radio>
              <el-button
                v-show="sycWechatTagParam.wechatUserType === 2"
                type="text"
                @click="addUser"
                >添加</el-button
              >
            </el-radio-group>
            <span v-show="sycWechatTagParam.wechatUserType === 2"
              >已选择{{ sendUserList.length }}个企微</span
            >
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button
          @click="
            sycWechatTagParam.corpIdList = null;
            sycWechatTagParam.wechatUserList = null;
            sycWechatTagParam.wechatUserType = 2;
            sendUserList = [];
            sycWechatTagDialog = false;
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="sycWechatTagSubmit">确 定</el-button>
      </div>
    </el-dialog>
    <selectCreateWay ref="selectCreateWayRef" @selectData="selectData" />
    <SelectUser ref="selectUserRef" @selectUser="selectUserData"></SelectUser>
    <importUser
      ref="importUserRef"
      @handleSuccess="
        params.page = 1;
        getList();
      "
    ></importUser>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import {
  // getTagGroup,
  newGetTagGroup,
  saveOrUpdateGroup,
  deleteGroup,
  getTagList,
  // deleteTag,
  // switchTag,
  makeSign,
  changeSort,
} from "@/api/labelManagement/index";
import {
  startTag,
  stopTag,
  sycWechatTag,
  deleteTag,
  updateTag,
} from "@/api/labelManagement/newIndex";
// import { getSubjectList } from "@/api/hxl/public";
import selectCreateWay from "./components/selectCreateWay.vue";
import SelectUser from "@/components/selectUser";
import importUser from "./components/importUser";
export default {
  components: {
    draggable,
    selectCreateWay,
    SelectUser,
    importUser,
  },
  name: "downloadCenter",
  data: function () {
    return {
      tagType: 0, // 0自定义标签，1系统标签
      groupList: [],
      isshowAdd: false,
      type: null,
      ruleForm: {
        id: "",
        groupName: "",
        pid: "0",
      },

      date: [],
      columns: [
        { prop: "tagName", label: "标签名称" },
        { prop: "tagValueLabels", label: "标签值" },
        { prop: "createWay", label: "标签类型" },
        { prop: "status", label: "标签状态" },
        { prop: "coverNum", label: "标签覆盖人数" },
        {
          prop: "isSycWechat",
          label: "企微同步状态",
          formatter: (row, index) => {
            if (row.isSycWechat == 0) return "待同步";
            if (row.isSycWechat == 1) return "未同步";
            if (row.isSycWechat == 2) return "同步中";
            if (row.isSycWechat == 3) return "已完成";
          },
        },
        { prop: "createDate", label: "创建时间" },
        { prop: "createUser", label: "创建人" },
        {
          prop: "default",
          label: "操作",
          width: 290,
          fixed: "right",
          align: "left",
        },
      ],
      total: 0,
      currentIndex: null,
      tableData: [],
      params: {
        groupId: "",
        createWay: "",
        page: 1,
        pageSize: 10,
        status: "",
      },
      intervalTodo: null,
      sycWechatTagDialog: false,
      sycWechatTagParam: {
        tagId: null,
        corpIdList: [],
        wechatUserList: null,
        wechatUserType: 2,
      },
      sycWechatTagRule: {
        wechatUserType: [
          {
            required: true,
            trigger: "change",
            validator: (rule, valaue, callback) => {
              if (
                (valaue === 2 &&
                  !this.$data.sycWechatTagParam.wechatUserList) ||
                (this.$data.sycWechatTagParam.wechatUserList &&
                  !this.$data.sycWechatTagParam.wechatUserList.length)
              ) {
                callback(new Error("请选择企微成员"));
              } else {
                callback();
              }
            },
          },
        ],
      },
      sendUserList: [], //选择的用户列表
      corpList: [], //主体数据列表
    };
  },
  created() {
    // this.getList();
    if (this.$route.query && [0, 1].includes(this.$route.query.category)) {
      this.tagType = this.$route.query.category;
    }
    this.getTagGroup();
    this.intervalTodo = setInterval(() => {
      this.getList();
    }, 600000);
  },
  mounted() {},
  beforeDestroy() {
    clearInterval(this.intervalTodo);
    this.intervalTodo = null;
  },
  methods: {
    handleSearch() {
      this.params.page = 1;
      this.params.beginDate =
        this.date && this.date[0] ? this.date[0] + " 00:00:00" : "";
      this.params.endDate =
        this.date && this.date[1] ? this.date[1] + " 23:59:59" : "";
      // this.getList();
      this.getTagGroup();
    },
    getList() {
      getTagList(this.params).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.data;
          this.total = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    treeNodeClick(data) {
      this.params.groupId = data.id;
      this.getList();
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      this.ruleForm.groupName = draggingNode.data.name;
      this.ruleForm.id = draggingNode.data.id;
      // this.submitGroup();
      let arr = [];
      dropNode.parent.data.children.forEach((item) => {
        arr.push(item.id);
      });
      changeSort(arr)
        .then((res) => {
          if (res.code != 200) {
            this.$message({
              type: "error",
              message: res.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      console.log("tree drop: ", draggingNode, dropNode, dropType);
    },
    /**
     * 判断是否允许拖拽节点放置到目标节点
     *
     * @param draggingNode 拖拽中的节点
     * @param dropNode 目标节点
     * @param type 放置类型，可选值为 'inner'（内部放置）和其他值
     * @returns 如果允许放置则返回 true，否则返回 false
     */
    allowDrop(draggingNode, dropNode, type) {
      if (type !== "inner") {
        if (draggingNode.parent.data.id == dropNode.parent.data.id) {
          return true;
        }
      }
    },
    /**
     * 判断节点是否允许拖拽
     *
     * @param draggingNode 拖拽的节点对象
     * @returns 若节点层级为3，则返回true，表示允许拖拽；否则返回false
     */
    allowDrag(draggingNode) {
      return draggingNode.level === 3;
      // return true
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.params.page = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.getList();
    },
    //新建/编辑分组
    addGroup(row, type) {
      this.type = type;
      if (type == 2) {
        this.$set(this.ruleForm, "groupName", row.name);
        this.ruleForm.id = row.id;
      } else {
        this.ruleForm.groupName = "";
        this.ruleForm.id = "";
        this.ruleForm.pid = row ? row.id : "";
      }

      this.isshowAdd = true;
    },
    handelClose() {
      this.isshowAdd = false;
    },
    //保存分组
    submitGroup() {
      if (!this.ruleForm.groupName) {
        this.$message.error("请输入分组名称");
        return;
      }
      saveOrUpdateGroup(this.ruleForm).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message);
          this.isshowAdd = false;
          this.getTagGroup();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //删除分组
    deleteGroup(item) {
      this.$confirm("确认删除分组", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let arr = [];
        arr.push(item.id);
        let params = {
          id: arr.toString(),
        };
        deleteGroup(params).then((res) => {
          if (res.code == 200) {
            this.getTagGroup();
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
    // 删除标签
    labelDel(row) {
      this.$confirm("此操作将永久删除该标签, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteTag({ tagId: row.id })
            .then((res) => {
              if (res.code == 200) {
                this.$message.success(res.message);
                this.getList();
              } else {
                this.$message.error(res.message);
              }
            })
            .catch((err) => {
              console.log("labelDel:", err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消删除",
          });
        });
    },
    //创建编辑标签
    addLabel() {
      this.$refs.selectCreateWayRef.isShowCreateWay = true;
    },
    editLabel(row) {
      if (row.createWay != "动态标签") {
        this.openPage("labelAdd", {
          id: row ? row.id : "",
          createWay: row.createWay,
          title: "编辑标签",
          category: row.category,
        });
      } else {
        this.openPage("dynamicRule", {
          id: row ? row.id : "",
          createWay: row.createWay,
          title: "编辑标签",
        });
      }
    },
    copyTag(row) {
      if (row.createWay != "动态标签") {
        this.openPage("labelAdd", {
          id: row ? row.id : "",
          createWay: row.createWay,
          title: "复制标签",
          category: row.category,
          isCopy: 1,
        });
      } else {
        this.openPage("dynamicRule", {
          id: row ? row.id : "",
          createWay: row.createWay,
          title: "复制标签",
          isCopy: 1,
        });
      }
    },
    upDateTag(row) {
      updateTag(row.id).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message);
          this.getList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //
    labelDetail(row) {
      this.$router.push({
        name: "LabelManageMentDetail",
        query: {
          id: row ? row.id : "",
        },
      });
    },
    //获取标签分组
    getTagGroup() {
      let params = {
        pageNumber: 1,
        pageSize: 100,
        associatedData: false,
        category: this.tagType,
      };
      newGetTagGroup(params).then((res) => {
        if (res.code == 200) {
          this.groupList =
            this.tagType == 0
              ? [...[{ id: "", isPreset: 0, name: "全部分组" }], ...res.data]
              : (this.groupList = res.data);
          if (this.tagType) {
            this.params.groupId = res.data[0].id;
          } else {
            this.params.groupId = "";
          }
          this.getList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //禁用/启用标签
    changeStatus(row) {
      let switchTag = row.status == 0 ? stopTag : startTag;
      switchTag(row.id).then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message);
          this.handleSearch();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    selectData(data) {
      if (data == "CALCULATE") {
        this.openPage("dynamicRule", {
          createWay: data,
          title: "创建标签",
        });
        return;
      }
      this.openPage("labelAdd", {
        createWay: data,
        title: "创建标签",
      });
    },
    // 全部更新
    allUpdate() {
      makeSign().then((res) => {
        if (res.code == 200) {
          this.$message.success(res.message);
          this.handleSearch();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 同步至企微标签
    synchronization(row) {
      this.sycWechatTagParam.tagId = row.id;
      this.sycWechatTagDialog = true;
    },
    addUser() {
      this.$refs.selectUserRef.selectUserShow = true;
      this.$nextTick(() => {
        this.$refs.selectUserRef.userList = this.sendUserList;
        this.$refs.selectUserRef.corpId = this.sycWechatTagParam.corpIdList;
        this.$refs.selectUserRef.$refs.tree.setCheckedKeys([]);
        this.$refs.selectUserRef.getUserWatch();
        // this.sendUserList.forEach((item) => {
        //   this.$refs.selectUserRef.$refs.tree.setChecked(item.id, true);
        // });
      });
    },
    selectUserData(val) {
      this.sycWechatTagParam.wechatUserList = [];
      this.sendUserList = val.userList;
      console.log(this.sendUserList);
      this.sendUserList.forEach((item) => {
        this.sycWechatTagParam.wechatUserList.push(item.userId);
      });
      this.sycWechatTagParam.corpIdList = val.corpList;
    },
    sycWechatTagSubmit() {
      const data = JSON.parse(JSON.stringify(this.sycWechatTagParam));
      this.$refs.sycWechatTagRefs.validate((valid) => {
        if (valid) {
          sycWechatTag(data)
            .then((res) => {
              if (res.code == 200) {
                this.$message.success(res.message);
                this.sycWechatTagDialog = false;
                this.getList();
              } else {
                this.$message.error(res.message);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    // 导入用户
    importUser() {
      this.$refs.importUserRef.visible = true;
      this.$refs.importUserRef.tagBizType = "MANUAL";
    },
  },
};
</script>

<style lang="scss" scoped>
body .el-table th.gutter {
  display: table-cell !important;
}
.btn_top {
  display: flex;
  justify-content: space-between;
}
.status {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: block;
    background-color: #0ccd6c;
    margin-right: 6px;
  }

  .waring {
    background-color: #fe0c0c;
  }
}

.content_box {
  display: flex;

  .group_box {
    width: 328px;
    height: 530px;
    font-size: 14px;
    color: #333;
    // border: 1px solid #dfe6ec;
    margin-right: 8px;
    flex-shrink: 0;
    .el-radio-group {
      width: 100%;
      .el-radio-button {
        width: 50%;
        text-align: center;
        .tagTypeStyle {
          width: 122px;
        }
      }
    }

    .list_view {
      margin-top: 10px;
      border: 1px solid #dfe6ec;
    }

    .add_box {
      font-size: 14px;
      height: 40px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #dfe6ec;
      cursor: pointer;
      color: #1890ff;

      .el-icon-plus {
        border: 1px solid #1890ff;
        margin: 0 10px 0 10px;
      }

      .el-icon-plus:before {
        font-size: 14px;
        color: #1890ff;
      }
    }

    .list_box {
      height: 488px;
      overflow-y: auto;

      .custom-tree-node {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 10px;
        width: calc(100% - 10px);
      }

      .name {
        margin-left: 10px;
        font-size: 14px;
      }

      .oprate_box {
        [class^="el-icon-"],
        [class*=" el-icon-"] {
          font-size: 14px;
          color: #1999dc;
        }

        .el-icon-s-fold {
          margin-right: 10px;
        }

        .el-icon-edit {
          font-size: 14px;
          color: #1999dc;
        }
        .el-icon-delete {
          font-size: 14px;
          color: #1999dc;
        }
        .el-icon-s-operation {
          font-size: 14px;
          margin-right: 10px;
          color: #1999dc;
        }
      }
      .el-icon-copy-document {
        margin: 0 10px 0 10px;
        font-size: 18px;
        color: #1890ff;
      }

      .el-icon-more {
        margin-right: 20px;
        color: #6f7173;
        font-size: 12px;
        transform: rotate(90deg);
      }
    }
  }
  .table_box {
    flex: 1;
    width: calc(100% - 305px);
  }
}
</style>
