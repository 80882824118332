import {
  getGuanYiProductList,
  mapList,
  getProjectList,
} from "@/api/hxl/public";
import { getCurrRecordList } from "@/api/labelManagement/index.js";
export let curriculumCodeList = [];
export let goodsList = [];
export let curriculumProjectList = [];
export let dictData = [];
export function getCurrRecord() {
  return getCurrRecordList({
    pageNumber: 1,
    pageSize: 1000,
  })
    .then((res) => {
      if (res.code == 200) {
        curriculumCodeList = res.data;
        curriculumCodeList.forEach((item) => {
          if (item.children && item.children.length > 0) {
            item.children.forEach((child) => {
              child.children = null;
            });
          }
        });
        return curriculumCodeList;
      }
    })
    .catch((err) => {});
}
// 获取产品数据
export function getGoodList() {
  return getGuanYiProductList({ page: 1, pageSize: 3000 })
    .then((res) => {
      if (res.code == 200) {
        goodsList = res.data.data;
        return goodsList;
      }
    })
    .catch((err) => {
      console.log(err);
    });
}
// 获取获取项目
export function curriculumProject() {
  return getProjectList({ page: 1, pageSize: 100 })
    .then((res) => {
      if (res.code == 200) {
        curriculumProjectList = res.data.data;
        return curriculumProjectList;
      }
    })
    .catch((err) => {});
}
// 字典
export function getMaplist() {
  let dictName = "curr_level,curriculum_type";
  return mapList({ dictName: dictName })
    .then((res) => {
      dictData = res.data;
      return dictData;
    })
    .catch((err) => {});
}

// 递归删除对象中的某个key值
export function removeKey(obj, theKey = "id") {
  for (let key in obj) {
    if (key === theKey) {
      delete obj[key];
    } else if (Object.prototype.toString.call(obj[key]) === "[object Object]") {
      obj[key] = removeKey(obj[key], theKey);
    } else if (Object.prototype.toString.call(obj[key]) === "[object Array]") {
      for (let i = 0; i < obj[key].length; i++) {
        obj[key][i] = removeKey(obj[key][i], theKey);
      }
    }
  }
  return obj;
}
