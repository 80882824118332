var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main addCustomerGroup", attrs: { id: "main" } },
    [
      _c("div", { staticClass: "tab_bar" }, [_vm._v("基础信息")]),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "ruleFormRef",
          attrs: {
            rules: _vm.formDataRules,
            model: _vm.ruleForm,
            "label-width": "80px",
            "label-position": "left",
          },
        },
        [
          _c(
            "el-row",
            { staticClass: "base_info" },
            [
              _c(
                "el-form-item",
                { attrs: { label: "标签名称", prop: "tagName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      placeholder: "请输入标签名称,30字符以内",
                      maxlength: "30",
                    },
                    model: {
                      value: _vm.ruleForm.tagName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "tagName", $$v)
                      },
                      expression: "ruleForm.tagName",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                { attrs: { label: "标签分组", prop: "tagGroupId" } },
                [
                  _c("CascaderFix", {
                    attrs: {
                      options: _vm.groupList,
                      props: { emitPath: false, label: "name", value: "id" },
                      "collapse-tags": "",
                      clearable: "",
                    },
                    model: {
                      value: _vm.ruleForm.tagGroupId,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "tagGroupId", $$v)
                      },
                      expression: "ruleForm.tagGroupId",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "tab_bar" }, [_vm._v("使用规则")]),
          _vm._v(" "),
          _c(
            "el-row",
            { staticClass: "base_info" },
            [
              ["CUSTOM"].includes(_vm.ruleForm.tagBizType)
                ? _c(
                    "el-form-item",
                    { attrs: { label: "命中规则", prop: "hittingMode" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.ruleForm.hittingMode,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "hittingMode", $$v)
                            },
                            expression: "ruleForm.hittingMode",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("按顺序命中符合规则第一个标签"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("命中符合规则的所有标签"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              ["CUSTOM"].includes(_vm.ruleForm.tagBizType)
                ? _c(
                    "el-form-item",
                    { attrs: { label: "更新方式", prop: "triggerMode" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: {
                            change: function ($event) {
                              _vm.ruleForm.timingWay = null
                              _vm.ruleForm.timingInterval = null
                            },
                          },
                          model: {
                            value: _vm.ruleForm.triggerMode,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "triggerMode", $$v)
                            },
                            expression: "ruleForm.triggerMode",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "MANUAL" } }, [
                            _vm._v(" 手动更新"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: "TIMING" } }, [
                            _vm._v(" 定时更新"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("手动更新需要手动点击更新按钮才能更新相应数据"),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("定时更新则在设定的时间将自动触发并更新数据"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ruleForm.triggerMode == "TIMING",
                              expression: "ruleForm.triggerMode == 'TIMING'",
                            },
                          ],
                          staticClass: "select",
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "150px" },
                              on: {
                                change: function ($event) {
                                  _vm.ruleForm.timingInterval = ""
                                },
                              },
                              model: {
                                value: _vm.ruleForm.timingWay,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ruleForm, "timingWay", $$v)
                                },
                                expression: "ruleForm.timingWay",
                              },
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "每天", value: 1 },
                              }),
                              _vm._v(" "),
                              _c("el-option", {
                                attrs: { label: "每三小时", value: 2 },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.ruleForm.timingWay == 1
                            ? _c("el-time-picker", {
                                attrs: {
                                  "picker-options": {
                                    selectableRange: "08:30:00 - 18:30:00",
                                  },
                                  "value-format": "HH:mm:ss",
                                  placeholder: "选择时间",
                                },
                                model: {
                                  value: _vm.ruleForm.timingInterval,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "timingInterval",
                                      $$v
                                    )
                                  },
                                  expression: "ruleForm.timingInterval",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              ["CUSTOM"].includes(_vm.ruleForm.tagBizType)
                ? _c(
                    "el-form-item",
                    { attrs: { label: "同步企微标签", required: "" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.asyncWorkWxTag,
                            callback: function ($$v) {
                              _vm.asyncWorkWxTag = $$v
                            },
                            expression: "asyncWorkWxTag",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: true } }, [
                            _vm._v("开启"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: false } }, [
                            _vm._v("关闭"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.asyncWorkWxTag
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "新建企微标签分组" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "200px" },
                                attrs: { type: "text" },
                                model: {
                                  value: _vm.ruleForm.automaticActions[0].group,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.ruleForm.automaticActions[0],
                                      "group",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "ruleForm.automaticActions[0].group",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "企微成员" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: { click: _vm.selectWorkWx },
                            },
                            [_vm._v("选择成员")]
                          ),
                          _vm._v(" "),
                          _c("span", [
                            _vm._v(
                              "已选择" +
                                _vm._s(
                                  this.ruleForm.automaticActions[0].staffWxList
                                    .length
                                ) +
                                "个微信"
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              ["CUSTOM", "MANUAL"].includes(_vm.ruleForm.tagBizType)
                ? _c(
                    "el-form-item",
                    { attrs: { label: "标签值", required: "" } },
                    [
                      _c("div", { staticClass: "label_box" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "draggable",
                              {
                                attrs: { group: "people", filter: ".unmover" },
                                model: {
                                  value: _vm.labelValueList,
                                  callback: function ($$v) {
                                    _vm.labelValueList = $$v
                                  },
                                  expression: "labelValueList",
                                },
                              },
                              [
                                _vm._l(
                                  _vm.labelValueList,
                                  function (item, index) {
                                    return _c(
                                      "el-input",
                                      {
                                        key: index,
                                        ref: "inputRef",
                                        refInFor: true,
                                        class: {
                                          unmover:
                                            _vm.ruleForm.id &&
                                            ["CALCULATE", "RFM"].includes(
                                              _vm.ruleForm.tagBizType
                                            ),
                                          inputFocus: _vm.currentIndex == index,
                                        },
                                        staticStyle: { width: "200px" },
                                        attrs: {
                                          placeholder: "请输入标签值",
                                          type: "text",
                                          disabled:
                                            !!_vm.ruleForm.id &&
                                            ["CALCULATE", "RFM"].includes(
                                              _vm.ruleForm.tagBizType
                                            ),
                                          maxlength: "20",
                                        },
                                        on: {
                                          focus: function ($event) {
                                            return _vm.focus(index)
                                          },
                                        },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.clickInput(index)
                                          },
                                        },
                                        model: {
                                          value: item.valueName,
                                          callback: function ($$v) {
                                            _vm.$set(item, "valueName", $$v)
                                          },
                                          expression: "item.valueName",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            attrs: { slot: "suffix" },
                                            slot: "suffix",
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-delete",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteLabelValue(
                                                    index
                                                  )
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("i", {
                                              staticClass: "el-icon-s-fold",
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !(
                                          _vm.ruleForm.id &&
                                          ["MANUAL"].includes(
                                            _vm.ruleForm.tagBizType
                                          )
                                        ),
                                        expression:
                                          "\n                  !(ruleForm.id && ['MANUAL'].includes(ruleForm.tagBizType))\n                ",
                                      },
                                    ],
                                    staticClass: "unmover",
                                    staticStyle: { "margin-bottom": "10px" },
                                    attrs: { type: "primary", plain: "" },
                                    on: { click: _vm.addLabelValue },
                                  },
                                  [_vm._v("添加标签值")]
                                ),
                              ],
                              2
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "标签规则" } }, [
            _c("span", [_vm._v("将使用以下选择规则计算出来的值作为标签展示")]),
          ]),
          _vm._v(" "),
          _c("el-form-item", { attrs: { label: "  " } }, [
            _c("div", { staticClass: "tagContent" }, [
              _c("div", { staticClass: "tagBox" }, [
                _c(
                  "div",
                  { staticClass: "contentRight" },
                  _vm._l(
                    _vm.labelValueList[_vm.currentIndex].customConditions,
                    function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "fatherBox" },
                        [
                          _c("div", { staticClass: "conditionNumBox" }, [
                            _vm._v("条件" + _vm._s(index + 1)),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticStyle: { width: "100%" } }, [
                            _c("div", { staticClass: "fatherContent" }, [
                              !["nestedTags", "qw_remark", "5a"].includes(
                                item.firstRules[0].dataKey
                              )
                                ? _c(
                                    "div",
                                    [
                                      _c("el-date-picker", {
                                        staticStyle: { "margin-right": "5px" },
                                        attrs: {
                                          type: "daterange",
                                          "range-separator": "至",
                                          "start-placeholder": "开始日期",
                                          "end-placeholder": "结束日期",
                                          format: "yyyy-MM-dd",
                                          "value-format": "yyyy-MM-dd",
                                          "picker-options": _vm.pickerOptions,
                                        },
                                        on: {
                                          blur: function ($event) {
                                            return _vm.firstRulesInputsChange(
                                              index
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.firstRules[0].inputs,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item.firstRules[0],
                                              "inputs",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "item.firstRules[0].inputs",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.ruleForm.tagBizType == "CUSTOM"
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: {
                                                width: "100px",
                                                "margin-right": "5px",
                                              },
                                              attrs: { placeholder: "请选择" },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.firstRules1OperatorChange(
                                                    item,
                                                    item.firstRules[1].operator,
                                                    index
                                                  )
                                                },
                                              },
                                              model: {
                                                value:
                                                  item.firstRules[1].operator,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item.firstRules[1],
                                                    "operator",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.firstRules[1].operator",
                                              },
                                            },
                                            _vm._l(
                                              _vm.actionOptions,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.value,
                                                  attrs: {
                                                    label: item.label,
                                                    value: item.value,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "210px" },
                                          attrs: { placeholder: "请选择" },
                                          on: {
                                            change: function ($event) {
                                              return _vm.actionTypeChange(
                                                index,
                                                _vm.firstRulesInputs[
                                                  _vm.currentIndex + "-" + index
                                                ]
                                              )
                                            },
                                          },
                                          model: {
                                            value:
                                              _vm.firstRulesInputs[
                                                _vm.currentIndex + "-" + index
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.firstRulesInputs,
                                                _vm.currentIndex + "-" + index,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "firstRulesInputs[`${currentIndex}-${index}`]",
                                          },
                                        },
                                        _vm._l(
                                          _vm.actionTypeOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          model: {
                                            value: item.firstRules[0].dataKey,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.firstRules[0],
                                                "dataKey",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.firstRules[0].dataKey",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: {
                                              label: "用户企微备注",
                                              value: "qw_remark",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "用户标签",
                                              value: "nestedTags",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-option", {
                                            attrs: {
                                              label: "用户5A等级",
                                              value: "5a",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { placeholder: "请选择" },
                                          on: {
                                            change: function ($event) {
                                              _vm.customConditionsIndex = index
                                              ;["EMPTY", "NOT_EMPTY"].includes(
                                                item.firstRules[0].operator
                                              )
                                                ? (item.firstRules[0].inputs =
                                                    [])
                                                : ""
                                            },
                                            focus: function ($event) {
                                              return _vm.defaultLogicOptionsDisabled(
                                                item.firstRules[0].dataKey
                                              )
                                            },
                                          },
                                          model: {
                                            value: item.firstRules[0].operator,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item.firstRules[0],
                                                "operator",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "item.firstRules[0].operator",
                                          },
                                        },
                                        _vm._l(
                                          _vm.defaultLogicOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                                disabled: item.disable,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _vm._v(" "),
                                      !["EMPTY", "NOT_EMPTY"].includes(
                                        item.firstRules[0].operator
                                      ) &&
                                      item.firstRules[0].dataKey == "nestedTags"
                                        ? _c(
                                            "p",
                                            { staticClass: "tagSelect" },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: { size: "medium" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.oepneTags(
                                                        item,
                                                        item.firstRules[0]
                                                          .inputs,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("点击选择")]
                                              ),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  "已选择" +
                                                    _vm._s(
                                                      item.firstRules[0].inputs
                                                        .length
                                                    ) +
                                                    "条数据"
                                                ),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      [
                                        "EQUAL",
                                        "NOT_EQUAL",
                                        "CONTAIN",
                                      ].includes(item.firstRules[0].operator) &&
                                      ["5a", "qw_remark"].includes(
                                        item.firstRules[0].dataKey
                                      )
                                        ? _c(
                                            "p",
                                            { staticClass: "tagSelect" },
                                            [
                                              item.firstRules[0].dataKey ==
                                              "qw_remark"
                                                ? _c("el-input", {
                                                    attrs: {
                                                      placeholder:
                                                        "请输入企微备注",
                                                    },
                                                    model: {
                                                      value:
                                                        item.firstRules[0]
                                                          .inputs[0],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item.firstRules[0]
                                                            .inputs,
                                                          0,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "item.firstRules[0].inputs[0]",
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm._v(" "),
                                              item.firstRules[0].dataKey == "5a"
                                                ? _c(
                                                    "el-select",
                                                    {
                                                      attrs: {
                                                        multiple:
                                                          item.firstRules[0]
                                                            .operator ===
                                                          "CONTAIN"
                                                            ? true
                                                            : false,
                                                      },
                                                      model: {
                                                        value:
                                                          item.firstRules[0]
                                                            .inputs[0],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item.firstRules[0]
                                                              .inputs,
                                                            0,
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.firstRules[0].inputs[0]",
                                                      },
                                                    },
                                                    [
                                                      _c("el-option", {
                                                        attrs: {
                                                          label: "A1",
                                                          value: 1,
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("el-option", {
                                                        attrs: {
                                                          label: "A2",
                                                          value: 2,
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("el-option", {
                                                        attrs: {
                                                          label: "A3",
                                                          value: 3,
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("el-option", {
                                                        attrs: {
                                                          label: "A4",
                                                          value: 4,
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("el-option", {
                                                        attrs: {
                                                          label: "A5",
                                                          value: 5,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "rightBox" },
                                [
                                  _vm.ruleForm.tagBizType == "CUSTOM" &&
                                  item.firstRules[0].dataKey != "nestedTags" &&
                                  item.firstRules.length > 1 &&
                                  item.firstRules[1].operator != "NOT_EQUAL"
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addChild(item, index)
                                            },
                                          },
                                        },
                                        [_vm._v("新增细分条件")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.labelValueList[_vm.currentIndex]
                                    .customConditions.length > 1
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.delRules(
                                                "father",
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                            _vm._v(" "),
                            item.simpleTagRuleConditionDefinitions &&
                            item.simpleTagRuleConditionDefinitions.length
                              ? _c("div", { staticClass: "childBox" }, [
                                  _c("div", { staticClass: "childHint" }, [
                                    _vm._v("且满足"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "divider",
                                      class: {
                                        divider_non:
                                          item.simpleTagRuleConditionDefinitions &&
                                          item.simpleTagRuleConditionDefinitions
                                            .length <= 1,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.childSimpleChange(item)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              item
                                                .simpleTagRuleConditionDefinitions[0]
                                                .connector == "AND"
                                                ? "且"
                                                : "或"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticStyle: { width: "100%" } },
                                    _vm._l(
                                      item.simpleTagRuleConditionDefinitions,
                                      function (items, i) {
                                        return _c(
                                          "div",
                                          { key: i, staticClass: "tagBox" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "childContentRight",
                                              },
                                              [
                                                _c("tagManageVue", {
                                                  key: _vm.tagManageUpload,
                                                  attrs: {
                                                    rulesConfig:
                                                      _vm.rulesConfig[
                                                        _vm.currentIndex +
                                                          "-" +
                                                          index
                                                      ],
                                                    tagManageData: {
                                                      data: items,
                                                      childIndex: i,
                                                      fatherIndex: index,
                                                      type: "child",
                                                      curriculumCodeList:
                                                        _vm.curriculumCodeList,
                                                      goodsList: _vm.goodsList,
                                                      curriculumProjectList:
                                                        _vm.projectList,
                                                      dictData: _vm.dictData,
                                                    },
                                                    father:
                                                      _vm.ruleForm.tagBizType ==
                                                      "CUSTOM"
                                                        ? false
                                                        : true,
                                                    changeDataFuns:
                                                      _vm.changeData,
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "rightBox" },
                                                  [
                                                    _c(
                                                      "el-button",
                                                      {
                                                        attrs: { type: "text" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.delRules(
                                                              "child",
                                                              index,
                                                              i
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("删除")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.ruleForm.tagBizType == "CUSTOM" &&
                            item.firstRules.length > 1 &&
                            item.firstRules[1].operator != "NOT_EQUAL" &&
                            item.firstRules[0].dataKey != "nestedTags"
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "fatherContent",
                                    staticStyle: {
                                      "justify-content": "flex-start",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          filterable: "",
                                          placeholder: "请选择",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.aggDataKeyChange(index)
                                          },
                                        },
                                        model: {
                                          value:
                                            item
                                              .aggregationTagRuleConditionDefinitions[0]
                                              .dataKey,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item
                                                .aggregationTagRuleConditionDefinitions[0],
                                              "dataKey",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      item.aggregationTagRuleConditionDefinitions[0].dataKey\n                    ",
                                        },
                                      },
                                      _vm._l(
                                        _vm.dataKeyOptions[
                                          _vm.currentIndex + "-" + index
                                        ],
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.name,
                                              value: item.key,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "el-select",
                                      {
                                        staticStyle: { width: "100px" },
                                        attrs: {
                                          filterable: "",
                                          placeholder: "请选择",
                                        },
                                        model: {
                                          value:
                                            item
                                              .aggregationTagRuleConditionDefinitions[0]
                                              .operator,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              item
                                                .aggregationTagRuleConditionDefinitions[0],
                                              "operator",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "\n                      item.aggregationTagRuleConditionDefinitions[0].operator\n                    ",
                                        },
                                      },
                                      _vm._l(
                                        _vm.operatorOptions,
                                        function (item) {
                                          return _c("el-option", {
                                            key: item.value,
                                            attrs: {
                                              label: item.label,
                                              value: item.value,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      [
                                        _c("el-input", {
                                          staticStyle: { width: "200px" },
                                          attrs: { placeholder: "请输入" },
                                          model: {
                                            value:
                                              item
                                                .aggregationTagRuleConditionDefinitions[0]
                                                .inputs[0],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item
                                                  .aggregationTagRuleConditionDefinitions[0]
                                                  .inputs,
                                                0,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "\n                        item.aggregationTagRuleConditionDefinitions[0]\n                          .inputs[0]\n                      ",
                                          },
                                        }),
                                        _vm._v(" "),
                                        item
                                          .aggregationTagRuleConditionDefinitions[0]
                                          .operator == "BETWEEN"
                                          ? _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-right": "10px",
                                                },
                                              },
                                              [_vm._v("--")]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        item
                                          .aggregationTagRuleConditionDefinitions[0]
                                          .operator == "BETWEEN"
                                          ? _c("el-input", {
                                              staticStyle: { width: "200px" },
                                              attrs: { placeholder: "请输入" },
                                              model: {
                                                value:
                                                  item
                                                    .aggregationTagRuleConditionDefinitions[0]
                                                    .inputs[1],
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item
                                                      .aggregationTagRuleConditionDefinitions[0]
                                                      .inputs,
                                                    1,
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "\n                        item.aggregationTagRuleConditionDefinitions[0]\n                          .inputs[1]\n                      ",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                        ]
                      )
                    }
                  ),
                  0
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "addBtn" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.addFather("behavior")
                      },
                    },
                  },
                  [_vm._v("+用户行为")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.addFather("stats")
                      },
                    },
                  },
                  [_vm._v("+用户属性")]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.ruleForm.tagBizType == "RFM"
            ? _c(
                "el-form-item",
                { attrs: { label: "筛选条件" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        placeholder: "购买课程等级（多选）",
                      },
                      on: { change: _vm.curriculumLevelChange },
                      model: {
                        value: _vm.rfmFormData.levelCondition.inputs,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.rfmFormData.levelCondition,
                            "inputs",
                            $$v
                          )
                        },
                        expression: "rfmFormData.levelCondition.inputs",
                      },
                    },
                    _vm._l(_vm.dictData.curr_level, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        multiple: "",
                        placeholder: "购买课程项目（多选）",
                      },
                      on: { change: _vm.curriculumProjectChange },
                      model: {
                        value: _vm.rfmFormData.projectCondition.inputs,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.rfmFormData.projectCondition,
                            "inputs",
                            $$v
                          )
                        },
                        expression: "rfmFormData.projectCondition.inputs",
                      },
                    },
                    _vm._l(_vm.projectList, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.proRemake, value: item.id + "" },
                      })
                    }),
                    1
                  ),
                  _vm._v(" "),
                  _c("CascaderFix", {
                    ref: "currCascaderRef",
                    attrs: {
                      "popper-class": "last-check",
                      clearable: "",
                      filterable: "",
                      options: _vm.curriculumCodeList,
                      props: _vm.curriculumProps,
                      placeholder: "购买课程（多选）",
                    },
                    on: { change: _vm.curriculumCodeChange },
                    model: {
                      value: _vm.rfmFormData.codeCondition.inputs,
                      callback: function ($$v) {
                        _vm.$set(_vm.rfmFormData.codeCondition, "inputs", $$v)
                      },
                      expression: "rfmFormData.codeCondition.inputs",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "p",
                    {
                      staticStyle: {
                        margin: "0",
                        padding: "0",
                        color: "#dfdfdf",
                      },
                    },
                    [
                      _vm._v(
                        "\n        不选择筛选条件则默认全部，选择则将按照该条件筛选对应用户进行计算\n      "
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.tagBizType == "RFM"
            ? _c(
                "el-form-item",
                { attrs: { label: "指标选择", required: true } },
                [
                  _c(
                    "div",
                    { staticClass: "rfmContent" },
                    [
                      _c("rfmVue", {
                        attrs: {
                          rfmData: {
                            title: "Recency（最近一次消费日期到今天的天数）",
                            units: "天",
                            type: "R",
                            judgeValueType:
                              _vm.rfmFormData.recencyCondition.judgeValueType,
                            resultType:
                              _vm.rfmFormData.recencyCondition.resultType,
                            customComparison:
                              _vm.rfmFormData.recencyCondition.customComparison,
                            interval: _vm.rfmFormData.recencyCondition.interval,
                          },
                        },
                        on: { intervalFun: _vm.intervalFun },
                      }),
                      _vm._v(" "),
                      _c("rfmVue", {
                        attrs: {
                          rfmData: {
                            title: "Frequency（消费频率）",
                            units: "次",
                            type: "F",
                            judgeValueType:
                              _vm.rfmFormData.frequencyCondition.judgeValueType,
                            resultType:
                              _vm.rfmFormData.frequencyCondition.resultType,
                            customComparison:
                              _vm.rfmFormData.frequencyCondition
                                .customComparison,
                            interval:
                              _vm.rfmFormData.frequencyCondition.interval,
                          },
                        },
                        on: { intervalFun: _vm.intervalFun },
                      }),
                      _vm._v(" "),
                      _c("rfmVue", {
                        attrs: {
                          rfmData: {
                            title: "Monetary（消费金额）",
                            units: "元",
                            type: "M",
                            judgeValueType:
                              _vm.rfmFormData.monetaryCondition.judgeValueType,
                            resultType:
                              _vm.rfmFormData.monetaryCondition.resultType,
                            customComparison:
                              _vm.rfmFormData.monetaryCondition
                                .customComparison,
                            interval:
                              _vm.rfmFormData.monetaryCondition.interval,
                          },
                        },
                        on: { intervalFun: _vm.intervalFun },
                      }),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.ruleForm.tagBizType == "RFM"
            ? _c(
                "el-form-item",
                { attrs: { label: "RFM标签值", required: true } },
                [
                  _c(
                    "el-table",
                    { attrs: { data: _vm.rfmTableData, border: "" } },
                    [
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            label: "用户等级名称",
                            align: "right",
                            width: "160",
                            "label-class-name": "rotate-text",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "指标名称",
                              "header-align": "left",
                              width: "160",
                              align: "center",
                              "label-class-name": "rotate-text",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(scope.row.label) +
                                            "\n              "
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3782166487
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.rfmFormData.values, function (item, index) {
                        return _c("el-table-column", {
                          key: index,
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function (scope) {
                                  return [
                                    _vm.editRfmHead == index
                                      ? _c("el-input", {
                                          attrs: { type: "text" },
                                          on: {
                                            blur: function ($event) {
                                              _vm.editRfmHead = null
                                            },
                                          },
                                          model: {
                                            value: item.valueName,
                                            callback: function ($$v) {
                                              _vm.$set(item, "valueName", $$v)
                                            },
                                            expression: "item.valueName",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.editRfmHead != index
                                      ? _c("span", [
                                          _vm._v(_vm._s(item.valueName)),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "el-icon-edit",
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          _vm.editRfmHead = index
                                        },
                                      },
                                    }),
                                  ]
                                },
                              },
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("p", [
                                      _vm._v(_vm._s(item[scope.row.prop])),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "bottom_btn" },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                disabled:
                  _vm.ruleForm.tagBizType != "CUSTOM"
                    ? false
                    : _vm.labelValueList[_vm.currentIndex].customConditions
                        .length >= 1
                    ? false
                    : true,
              },
              on: { click: _vm.submit },
            },
            [_vm._v("保存")]
          ),
          _vm._v(" "),
          _c("el-button", { on: { click: _vm.backRouter } }, [_vm._v("取消")]),
        ],
        1
      ),
      _vm._v(" "),
      _c("selectCreateWay", {
        ref: "selectCreateWayRef",
        on: { selectData: _vm.selectData },
      }),
      _vm._v(" "),
      _c("selectTagVue", {
        ref: "selectTagRef",
        attrs: { tagTypeShow: false },
        on: { selectTags: _vm.selectTags },
      }),
      _vm._v(" "),
      _c("selectUserVue", {
        ref: "selectUserRef",
        on: { selectUser: _vm.selectUser },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }