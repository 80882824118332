<template>
  <el-dialog
    title="选择标签"
    :visible.sync="visibleDialog"
    width="1200px"
    top="10vh"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div>
      <el-form :inline="true">
        <el-form-item>
          <cascader-fix
            v-model="groupId"
            :options="tagOptions"
            :props="{
              multiple: true,
              value: 'id',
              label: 'name',
              emitPath: true,
            }"
            clearable
            @change="groupChange"
          ></cascader-fix>
        </el-form-item>
        <el-form-item>
          <el-input
            type="text"
            v-model="params.tagName"
            placeholder="请输入标签名称"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>
      <div class="tagMain">
        <div class="left">
          <div>
            <span>选择标签</span>
            <el-button type="text" @click="allUnfold">{{
              activeNames.length == tagList.length ? "全部收起" : "全部展开"
            }}</el-button>
          </div>
          <div class="tagTypeBox" v-if="tagTypeShow">
            <el-radio-group v-model="tagType" @change="tagTypeChange">
              <el-radio label="1">满足以下任一标签</el-radio>
              <el-radio label="2">全部满足以下标签</el-radio>
              <el-radio label="3">不包含任何标签</el-radio>
              <el-radio label="4">不含以下标签</el-radio>
            </el-radio-group>
          </div>
          <div class="allTagBox">
            <el-collapse v-model="activeNames" @change="handleChange">
              <el-collapse-item
                v-for="(item, index) in tagList"
                :key="index"
                :name="item.id"
              >
                <template slot="title">
                  {{ item.tagName }}
                  <el-button
                    type="text"
                    v-if="!isRadio"
                    :disabled="tagType == 3"
                    @click.stop="selectAll(item.tagValues)"
                    >全选</el-button
                  >
                </template>
                <el-tag
                  v-for="(items, index) in item.tagValues"
                  :key="index"
                  @click="selectTagVal(items)"
                  :effect="
                    selectTagList.some((tagItem) =>
                      typeof tagItem == 'object'
                        ? tagItem.tagId === items.tagId
                        : tagItem == items.tagId
                    )
                      ? 'dark'
                      : 'plain'
                  "
                  >{{ items.valueName }}</el-tag
                >
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
        <div class="right">
          <div>
            <span>已选择</span>
            <el-button v-if="!isRadio" type="text" @click="selectTagList = []"
              >全部取消</el-button
            >
          </div>
          <div class="tagBox">
            <el-tag
              v-for="(item, index) in selectTagList"
              :key="index"
              closable
              @close="removeSelectTag(item)"
              >{{ typeof item == "object" ? item.valueName : "" }}</el-tag
            >
          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
import {
  getDetails,
  getGroupTree,
  getTagList,
} from "@/api/labelManagement/index.js";
import CascaderFix from "@/components/cascaderFix.vue";
export default {
  name: "SelectTag",
  props: {
    isRadio: {
      type: Boolean,
      default: false,
    },
    tagTypeShow: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    CascaderFix,
  },
  data() {
    return {
      visibleDialog: false,
      activeNames: [],
      selectTagList: [], //选择标签值列表
      tagList: [], //所有标签列表
      tagOptions: [], //标签分组
      groupId: [],
      params: {
        groupId: "",
        createWay: "",
        page: 1,
        pageSize: 100,
        status: "",
        tagName: "",
        tagBizType: "",
      },
      tagType: "",
    };
  },
  created() {
    // this.getTagGroup();
    // this.getTagListData();
  },
  mounted() {},
  methods: {
    search() {
      this.activeNames = [];
      this.tagList = [];
      this.getTagListData();
    },
    getTagListData() {
      getTagList(this.params)
        .then((res) => {
          if (res.code == 200) {
            if (res.data.data) {
              this.tagList = res.data.data;
              this.tagList.forEach((tag) => {
                this.$set(tag, "tagValues", []);
              });
              if (this.selectTagList.length) {
                this.tagList.forEach((element, index) => {
                  this.activeNames.push(element.id);
                  if (element.tagValues.length == 0) {
                    this.getTagDetails(index, element.id);
                  }
                });
              }
            } else {
              this.$message.error("当前标签分组没有对应的标签");
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTagGroup() {
      let params = {
        pageNumber: 1,
        pageSize: 100,
        associatedData: false,
        category: 0,
      };
      getGroupTree(params).then((res) => {
        if (res.code == 200) {
          this.checkChildren(res.data);
          this.tagOptions = [
            ...[{ id: "", isPreset: 0, name: "全部分组" }],
            ...res.data,
          ];
        } else {
          this.$message.error(res.message);
        }
      });
    },
    checkChildren(data) {
      data.forEach((item) => {
        if (item.children.length === 0) {
          item.children = null;
        } else {
          this.checkChildren(item.children);
        }
      });
    },

    // getList() {
    // getTagList(this.params).then((res) => {
    //   if (res.code == 200) {
    //     this.tagList = res.data.data;
    //   } else {
    //     this.$message.error(res.message);
    //   }
    // });
    // },
    groupChange() {
      this.params.groupId = "";
      this.groupId.forEach((item) => {
        this.params.groupId += item[item.length - 1] + ",";
      });
      this.params.groupId = this.params.groupId.slice(0, -1);
    },
    handleChange() {
      this.tagList.forEach((item, index) => {
        if (this.activeNames.includes(item.id)) {
          if (item.tagValues.length == 0) {
            this.getTagDetails(index, item.id);
          }
        }
      });
    },
    allUnfold() {
      if (this.activeNames.length == this.tagList.length) {
        this.activeNames = [];
        return;
      }
      this.activeNames = [];
      this.tagList.forEach((element, index) => {
        this.activeNames.push(element.id);
        if (element.tagValues.length == 0) {
          this.getTagDetails(index, element.id);
        }
      });
    },
    getTagDetails(index, id) {
      getDetails({ tagId: id })
        .then((res) => {
          if (res.code == 200) {
            this.tagList[index].tagValues = res.data.tagValueList;
            if (
              this.tagList[index].tagValues &&
              this.tagList[index].tagValues.length
            ) {
              this.$nextTick(() => {
                this.tagList[index].tagValues.forEach((items) => {
                  this.selectTagList.forEach((item, index) => {
                    if (typeof item != "object" && item == items.tagId) {
                      this.$set(this.selectTagList, index, {
                        valueName: items.valueName,
                        tagId: items.tagId,
                      });
                    }
                  });
                });
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectAll(tagGroup) {
      this.selectTagList = this.removeDuplicates(
        this.selectTagList.concat(tagGroup),
        "tagId"
      );
    },
    selectTagVal(tag) {
      if (this.tagType == 3) return;
      if (this.selectTagList.some((tagItem) => tagItem.tagId === tag.tagId)) {
        return;
      }
      if (this.isRadio && this.selectTagList.length > 0) return;
      console.log(this.selectTagList);
      this.selectTagList.push(tag);
    },
    removeSelectTag(tag) {
      this.selectTagList = this.selectTagList.filter(
        (item) => item.tagId !== tag.tagId
      );
    },
    cancel() {
      this.visibleDialog = false;
    },
    submit() {
      if (!this.tagType && !this.isRadio) {
        this.$message({
          type: "warning",
          message: "请选择标签查询类型",
        });
        return;
      }
      this.$emit("selectTags", {
        selectTagList: this.selectTagList,
        tagType: this.tagType,
      });
      this.selectTagList = [];
      this.visibleDialog = false;
    },
    // 去重函数，根据传入字段去重
    removeDuplicates(arr, field) {
      let obj = {};
      return arr.reduce((cur, next) => {
        obj[next[field]] ? "" : (obj[next[field]] = true && cur.push(next));
        return cur;
      }, []);
    },
    // 标签类型切换
    tagTypeChange() {
      if (this.tagType == 3) {
        this.selectTagList = [];
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tagMain {
  display: flex;
  max-height: 58vh;
  overflow: hidden;
  .left {
    padding-right: 10px;
    width: 70%;
    .tagTypeBox {
      margin-bottom: 10px;
      line-height: 2;
      border-bottom: 1px solid #e6ebf5;
    }
    .allTagBox {
      height: 460px;
      overflow-y: scroll;
    }
  }
  .right {
    width: 30%;
    overflow-y: scroll;
  }
  ::v-deep .el-tag {
    margin-right: 5px;
    margin-bottom: 5px;
    cursor: pointer;
  }
}
</style>
