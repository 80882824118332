import request from "@/utils/request";

// 获取规则配置
export function getRulesConfig(data) {
  return request({
    url: "cdp-portrait/pc/tag/rulesConfig",
    method: "get",
    params: data,
  });
}
// 新增标签
export function addTag(data) {
  return request({
    url: "cdp-portrait/pc/tag/addTag",
    method: "post",
    data: data,
  });
}

// 修改标签
// export function modifyTag(data) {
//   return request({
//     url: "cdp-portrait/pc/tag/modifyTag",
//     method: "post",
//     data: data,
//   });
// }

// 新增、修改自定义标签
export function addModifyCustomTag(data) {
  return request({
    url: "cdp-portrait/pc/tag/addOrModifyCustomTag",
    method: "post",
    data: data,
  });
}

// 新增、修改计算标签addOrModifyCalculateTag
export function addModifyCalculateTag(data) {
  //原
  return request({
    url: "cdp-portrait/pc/tag/addModifyCalculateTag",
    method: "post",
    data: data,
  });
}

export function addOrModifyCalculateTag(data) {
  //新（2024/08/22）
  return request({
    url: "cdp-portrait/pc/tag/addOrModifyCalculateTag",
    method: "post",
    data: data,
  });
}

// 新增、修改rfm标签
export function addModifyRFMTag(data) {
  return request({
    url: "cdp-portrait/pc/tag/addOrModifyRMFTag",
    method: "post",
    data: data,
  });
}

// 新增、修改手动标签组
export function addOrModifyManualTag(data) {
  return request({
    url: "cdp-portrait/pc/tag/addOrModifyManualTag",
    method: "post",
    data: data,
  });
}

export function getTagInfoPreview(data) {
  return request({
    url: "cdp-portrait/pc/tag_info/preview",
    method: "get",
    params: data,
  });
}

// 表单提交数据回显
export function getFormDetail(tagId) {
  return request({
    url: "cdp-portrait/pc/tag/formDetail/" + tagId,
    method: "get",
  });
}

// 标签启用
export function startTag(tagId) {
  return request({
    url: "cdp-portrait/pc/tag/start/" + tagId,
    method: "get",
  });
}

// 标签禁用
export function stopTag(tagId) {
  return request({
    url: "cdp-portrait/pc/tag/stop/" + tagId,
    method: "get",
  });
}

// 单个更新
export function updateTag(tagId) {
  return request({
    url: "cdp-portrait/pc/tag/update/" + tagId,
    method: "get",
  });
}
// 同步至企微标签
export function sycWechatTag(data) {
  return request({
    url: "cdp-portrait/pc/tag_info/sycWechatTag",
    method: "post",
    data: data,
  });
}

// 删除标签
export function deleteTag(data) {
  return request({
    url: "cdp-portrait/pc/tag_info/deleteTag",
    method: "post",
    params: data,
  });
}

// 获取企微标签分组
// export function getTagGroup(data) {
//   return request({
//     url: "inner-crm/pc/curriculumInvitation/getTagGroup",
//     method: "get",
//     params: data,
//   });
// }
