<template>
  <div class="main addCustomerGroup" id="main">
    <div class="tab_bar">基础信息</div>
    <el-form
      :rules="formDataRules"
      ref="ruleFormRef"
      :model="ruleForm"
      label-width="80px"
      label-position="left"
    >
      <el-row class="base_info">
        <el-form-item label="标签名称" prop="tagName">
          <el-input
            v-model="ruleForm.tagName"
            placeholder="请输入标签名称,30字符以内"
            style="width: 200px"
            maxlength="30"
          />
        </el-form-item>
        <el-form-item label="标签分组" prop="tagGroupId">
          <CascaderFix
            v-model="ruleForm.tagGroupId"
            :options="groupList"
            :props="{ emitPath: false, label: 'name', value: 'id' }"
            collapse-tags
            clearable
          ></CascaderFix>
        </el-form-item>
      </el-row>

      <div class="tab_bar">使用规则</div>
      <el-row class="base_info">
        <el-form-item
          label="命中规则"
          prop="hittingMode"
          v-if="['CUSTOM'].includes(ruleForm.tagBizType)"
        >
          <el-radio-group v-model="ruleForm.hittingMode">
            <el-radio :label="2">按顺序命中符合规则第一个标签</el-radio>
            <el-radio :label="0">命中符合规则的所有标签</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="更新方式"
          prop="triggerMode"
          v-if="['CUSTOM'].includes(ruleForm.tagBizType)"
        >
          <el-radio-group
            v-model="ruleForm.triggerMode"
            @change="
              ruleForm.timingWay = null;
              ruleForm.timingInterval = null;
            "
          >
            <el-radio label="MANUAL"> 手动更新</el-radio>
            <!--<el-radio label="REAL_TIME"> 实时更新</el-radio>-->
            <el-radio label="TIMING"> 定时更新</el-radio>
          </el-radio-group>
          <p>手动更新需要手动点击更新按钮才能更新相应数据</p>
          <!--<p>实时更新将在用户触发规则时则会更新数据</p>-->
          <p>定时更新则在设定的时间将自动触发并更新数据</p>
          <div class="select" v-show="ruleForm.triggerMode == 'TIMING'">
            <el-select
              v-model="ruleForm.timingWay"
              style="width: 150px"
              @change="ruleForm.timingInterval = ''"
            >
              <el-option label="每天" :value="1" />
              <el-option label="每三小时" :value="2" />
            </el-select>
            <el-time-picker
              v-if="ruleForm.timingWay == 1"
              v-model="ruleForm.timingInterval"
              :picker-options="{
                selectableRange: '08:30:00 - 18:30:00',
              }"
              value-format="HH:mm:ss"
              placeholder="选择时间"
            >
            </el-time-picker>
          </div>
        </el-form-item>
        <el-form-item
          label="同步企微标签"
          required
          v-if="['CUSTOM'].includes(ruleForm.tagBizType)"
        >
          <el-radio-group v-model="asyncWorkWxTag">
            <el-radio :label="true">开启</el-radio>
            <el-radio :label="false">关闭</el-radio>
          </el-radio-group>
          <el-form-item v-if="asyncWorkWxTag" label="新建企微标签分组">
            <el-input
              v-model="ruleForm.automaticActions[0].group"
              type="text"
              style="width: 200px"
            ></el-input>
          </el-form-item>
          <el-form-item label="企微成员">
            <el-button type="text" @click="selectWorkWx">选择成员</el-button>
            <span
              >已选择{{
                this.ruleForm.automaticActions[0].staffWxList.length
              }}个微信</span
            >
          </el-form-item>
        </el-form-item>
        <el-form-item
          label="标签值"
          required
          v-if="['CUSTOM', 'MANUAL'].includes(ruleForm.tagBizType)"
        >
          <div class="label_box">
            <div>
              <draggable
                v-model="labelValueList"
                group="people"
                filter=".unmover"
              >
                <el-input
                  ref="inputRef"
                  placeholder="请输入标签值"
                  type="text"
                  v-model="item.valueName"
                  v-for="(item, index) in labelValueList"
                  :class="{
                    unmover:
                      ruleForm.id &&
                      ['CALCULATE', 'RFM'].includes(ruleForm.tagBizType),
                    inputFocus: currentIndex == index,
                  }"
                  :key="index"
                  :disabled="
                    !!ruleForm.id &&
                    ['CALCULATE', 'RFM'].includes(ruleForm.tagBizType)
                  "
                  maxlength="20"
                  @focus="focus(index)"
                  @click.native="clickInput(index)"
                  style="width: 200px"
                >
                  <div slot="suffix">
                    <i
                      class="el-icon-delete"
                      @click="deleteLabelValue(index)"
                    ></i>
                    <i class="el-icon-s-fold"></i>
                  </div>
                </el-input>
                <el-button
                  type="primary"
                  class="unmover"
                  style="margin-bottom: 10px"
                  plain
                  v-show="
                    !(ruleForm.id && ['MANUAL'].includes(ruleForm.tagBizType))
                  "
                  @click="addLabelValue"
                  >添加标签值</el-button
                >
              </draggable>
            </div>
          </div>
        </el-form-item>
      </el-row>
      <el-form-item label="标签规则">
        <span>将使用以下选择规则计算出来的值作为标签展示</span>
      </el-form-item>
      <el-form-item label="  ">
        <div class="tagContent">
          <div class="tagBox">
            <div class="contentRight">
              <!-- 父级 -->
              <div
                class="fatherBox"
                v-for="(item, index) in labelValueList[currentIndex]
                  .customConditions"
                :key="index"
              >
                <div class="conditionNumBox">条件{{ index + 1 }}</div>
                <div style="width: 100%">
                  <div class="fatherContent">
                    <div
                      v-if="
                        !['nestedTags', 'qw_remark', '5a'].includes(
                          item.firstRules[0].dataKey
                        )
                      "
                    >
                      <el-date-picker
                        v-model="item.firstRules[0].inputs"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptions"
                        style="margin-right: 5px"
                        @blur="firstRulesInputsChange(index)"
                      >
                      </el-date-picker>
                      <el-select
                        style="width: 100px; margin-right: 5px"
                        v-if="ruleForm.tagBizType == 'CUSTOM'"
                        v-model="item.firstRules[1].operator"
                        placeholder="请选择"
                        @change="
                          firstRules1OperatorChange(
                            item,
                            item.firstRules[1].operator,
                            index
                          )
                        "
                      >
                        <el-option
                          v-for="item in actionOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                      <el-select
                        v-model="firstRulesInputs[`${currentIndex}-${index}`]"
                        placeholder="请选择"
                        @change="
                          actionTypeChange(
                            index,
                            firstRulesInputs[`${currentIndex}-${index}`]
                          )
                        "
                        style="width: 210px"
                      >
                        <el-option
                          v-for="item in actionTypeOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                    <div v-else>
                      <el-select v-model="item.firstRules[0].dataKey">
                        <el-option
                          :label="'用户企微备注'"
                          :value="'qw_remark'"
                        ></el-option>
                        <el-option
                          :label="'用户标签'"
                          :value="'nestedTags'"
                        ></el-option>
                        <el-option
                          :label="'用户5A等级'"
                          :value="'5a'"
                        ></el-option>
                      </el-select>
                      <el-select
                        v-model="item.firstRules[0].operator"
                        placeholder="请选择"
                        @change="
                          customConditionsIndex = index;
                          ['EMPTY', 'NOT_EMPTY'].includes(
                            item.firstRules[0].operator
                          )
                            ? (item.firstRules[0].inputs = [])
                            : '';
                        "
                        @focus="
                          defaultLogicOptionsDisabled(
                            item.firstRules[0].dataKey
                          )
                        "
                      >
                        <el-option
                          v-for="item in defaultLogicOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                          :disabled="item.disable"
                        >
                        </el-option>
                      </el-select>
                      <p
                        class="tagSelect"
                        v-if="
                          !['EMPTY', 'NOT_EMPTY'].includes(
                            item.firstRules[0].operator
                          ) && item.firstRules[0].dataKey == 'nestedTags'
                        "
                      >
                        <el-button
                          size="medium"
                          @click="
                            oepneTags(item, item.firstRules[0].inputs, index)
                          "
                          >点击选择</el-button
                        >
                        <span
                          >已选择{{
                            item.firstRules[0].inputs.length
                          }}条数据</span
                        >
                      </p>
                      <p
                        class="tagSelect"
                        v-if="
                          ['EQUAL', 'NOT_EQUAL', 'CONTAIN'].includes(
                            item.firstRules[0].operator
                          ) &&
                          ['5a', 'qw_remark'].includes(
                            item.firstRules[0].dataKey
                          )
                        "
                      >
                        <el-input
                          v-if="item.firstRules[0].dataKey == 'qw_remark'"
                          v-model="item.firstRules[0].inputs[0]"
                          placeholder="请输入企微备注"
                        ></el-input>
                        <el-select
                          v-model="item.firstRules[0].inputs[0]"
                          :multiple="
                            item.firstRules[0].operator === 'CONTAIN'
                              ? true
                              : false
                          "
                          v-if="item.firstRules[0].dataKey == '5a'"
                        >
                          <el-option label="A1" :value="1"></el-option>
                          <el-option label="A2" :value="2"></el-option>
                          <el-option label="A3" :value="3"></el-option>
                          <el-option label="A4" :value="4"></el-option>
                          <el-option label="A5" :value="5"></el-option>
                        </el-select>
                      </p>
                    </div>
                    <div class="rightBox">
                      <el-button
                        type="text"
                        v-if="
                          ruleForm.tagBizType == 'CUSTOM' &&
                          item.firstRules[0].dataKey != 'nestedTags' &&
                          item.firstRules.length > 1 &&
                          item.firstRules[1].operator != 'NOT_EQUAL'
                        "
                        @click="addChild(item, index)"
                        >新增细分条件</el-button
                      >
                      <el-button
                        type="text"
                        @click="delRules('father', index)"
                        v-if="
                          labelValueList[currentIndex].customConditions.length >
                          1
                        "
                        >删除</el-button
                      >
                    </div>
                  </div>
                  <div
                    class="childBox"
                    v-if="
                      item.simpleTagRuleConditionDefinitions &&
                      item.simpleTagRuleConditionDefinitions.length
                    "
                  >
                    <div class="childHint">且满足</div>
                    <div
                      class="divider"
                      :class="{
                        divider_non:
                          item.simpleTagRuleConditionDefinitions &&
                          item.simpleTagRuleConditionDefinitions.length <= 1,
                      }"
                    >
                      <span @click="childSimpleChange(item)">{{
                        item.simpleTagRuleConditionDefinitions[0].connector ==
                        "AND"
                          ? "且"
                          : "或"
                      }}</span>
                    </div>
                    <!-- 子级 -->
                    <!-- 子集的 i 为父级的 index 。子集的数据格式为childRules = { i:[] } -->
                    <div style="width: 100%">
                      <div
                        class="tagBox"
                        v-for="(
                          items, i
                        ) in item.simpleTagRuleConditionDefinitions"
                        :key="i"
                      >
                        <div class="childContentRight">
                          <tagManageVue
                            :rulesConfig="
                              rulesConfig[`${currentIndex}-${index}`]
                            "
                            :tagManageData="{
                              data: items,
                              childIndex: i,
                              fatherIndex: index,
                              type: 'child',
                              curriculumCodeList: curriculumCodeList,
                              goodsList: goodsList,
                              curriculumProjectList: projectList,
                              dictData: dictData,
                            }"
                            :father="
                              ruleForm.tagBizType == 'CUSTOM' ? false : true
                            "
                            :key="tagManageUpload"
                            :changeDataFuns="changeData"
                          ></tagManageVue>
                          <div class="rightBox">
                            <el-button
                              type="text"
                              @click="delRules('child', index, i)"
                              >删除</el-button
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="fatherContent"
                    style="justify-content: flex-start"
                    v-if="
                      ruleForm.tagBizType == 'CUSTOM' &&
                      item.firstRules.length > 1 &&
                      item.firstRules[1].operator != 'NOT_EQUAL' &&
                      item.firstRules[0].dataKey != 'nestedTags'
                    "
                  >
                    <el-select
                      v-model="
                        item.aggregationTagRuleConditionDefinitions[0].dataKey
                      "
                      filterable
                      placeholder="请选择"
                      @change="aggDataKeyChange(index)"
                    >
                      <el-option
                        v-for="item in dataKeyOptions[
                          `${currentIndex}-${index}`
                        ]"
                        :key="item.value"
                        :label="item.name"
                        :value="item.key"
                      >
                      </el-option>
                    </el-select>
                    <el-select
                      v-model="
                        item.aggregationTagRuleConditionDefinitions[0].operator
                      "
                      filterable
                      placeholder="请选择"
                      style="width: 100px"
                    >
                      <el-option
                        v-for="item in operatorOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <div>
                      <el-input
                        v-model="
                          item.aggregationTagRuleConditionDefinitions[0]
                            .inputs[0]
                        "
                        style="width: 200px"
                        placeholder="请输入"
                      ></el-input>
                      <span
                        style="margin-right: 10px"
                        v-if="
                          item.aggregationTagRuleConditionDefinitions[0]
                            .operator == 'BETWEEN'
                        "
                        >--</span
                      >
                      <el-input
                        v-if="
                          item.aggregationTagRuleConditionDefinitions[0]
                            .operator == 'BETWEEN'
                        "
                        v-model="
                          item.aggregationTagRuleConditionDefinitions[0]
                            .inputs[1]
                        "
                        style="width: 200px"
                        placeholder="请输入"
                      ></el-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div
            class="tagfooter"
            v-if="ruleForm.tagBizType == 'CUSTOM'"
            style="padding-right: 10px"
          >
            <el-button type="text" @click="addFather">新增条件</el-button>
          </div> -->
        </div>
        <div class="addBtn">
          <el-button type="primary" @click="addFather('behavior')"
            >+用户行为</el-button
          >
          <el-button type="primary" @click="addFather('stats')"
            >+用户属性</el-button
          >
        </div>
      </el-form-item>
      <el-form-item label="筛选条件" v-if="ruleForm.tagBizType == 'RFM'">
        <el-select
          v-model="rfmFormData.levelCondition.inputs"
          multiple
          placeholder="购买课程等级（多选）"
          @change="curriculumLevelChange"
        >
          <el-option
            v-for="(item, index) in dictData.curr_level"
            :key="index"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
        <el-select
          v-model="rfmFormData.projectCondition.inputs"
          multiple
          placeholder="购买课程项目（多选）"
          @change="curriculumProjectChange"
        >
          <el-option
            v-for="item in projectList"
            :key="item.id"
            :label="item.proRemake"
            :value="item.id + ''"
          ></el-option>
        </el-select>
        <CascaderFix
          ref="currCascaderRef"
          popper-class="last-check"
          clearable
          filterable
          v-model="rfmFormData.codeCondition.inputs"
          :options="curriculumCodeList"
          :props="curriculumProps"
          placeholder="购买课程（多选）"
          @change="curriculumCodeChange"
        ></CascaderFix>
        <p style="margin: 0; padding: 0; color: #dfdfdf">
          不选择筛选条件则默认全部，选择则将按照该条件筛选对应用户进行计算
        </p>
      </el-form-item>
      <el-form-item
        label="指标选择"
        v-if="ruleForm.tagBizType == 'RFM'"
        :required="true"
      >
        <!-- <el-checkbox-group v-model="checkList">
          <el-checkbox label="复选框 A"
            >Recency（最近一次消费日期到今天的天数）</el-checkbox
          >
          <el-checkbox label="复选框 B">Frequency（消费频率）</el-checkbox>
          <el-checkbox label="复选框 C">Monetary（消费金额）</el-checkbox>
        </el-checkbox-group> -->
        <div class="rfmContent">
          <rfmVue
            :rfmData="{
              title: 'Recency（最近一次消费日期到今天的天数）',
              units: '天',
              type: 'R',
              judgeValueType: rfmFormData.recencyCondition.judgeValueType,
              resultType: rfmFormData.recencyCondition.resultType,
              customComparison: rfmFormData.recencyCondition.customComparison,
              interval: rfmFormData.recencyCondition.interval,
            }"
            @intervalFun="intervalFun"
          ></rfmVue>
          <rfmVue
            :rfmData="{
              title: 'Frequency（消费频率）',
              units: '次',
              type: 'F',
              judgeValueType: rfmFormData.frequencyCondition.judgeValueType,
              resultType: rfmFormData.frequencyCondition.resultType,
              customComparison: rfmFormData.frequencyCondition.customComparison,
              interval: rfmFormData.frequencyCondition.interval,
            }"
            @intervalFun="intervalFun"
          ></rfmVue>
          <rfmVue
            :rfmData="{
              title: 'Monetary（消费金额）',
              units: '元',
              type: 'M',
              judgeValueType: rfmFormData.monetaryCondition.judgeValueType,
              resultType: rfmFormData.monetaryCondition.resultType,
              customComparison: rfmFormData.monetaryCondition.customComparison,
              interval: rfmFormData.monetaryCondition.interval,
            }"
            @intervalFun="intervalFun"
          ></rfmVue>
        </div>
      </el-form-item>
      <el-form-item
        label="RFM标签值"
        v-if="ruleForm.tagBizType == 'RFM'"
        :required="true"
      >
        <el-table :data="rfmTableData" border>
          <el-table-column
            label="用户等级名称"
            align="right"
            width="160"
            :label-class-name="'rotate-text'"
          >
            <el-table-column
              label="指标名称"
              header-align="left"
              width="160"
              align="center"
              :label-class-name="'rotate-text'"
            >
              <template slot-scope="scope">
                <div>
                  {{ scope.row.label }}
                </div>
              </template>
            </el-table-column>
          </el-table-column>
          <el-table-column
            v-for="(item, index) in rfmFormData.values"
            :key="index"
          >
            <template slot="header" slot-scope="scope">
              <el-input
                type="text"
                v-if="editRfmHead == index"
                v-model="item.valueName"
                @blur="editRfmHead = null"
              ></el-input>
              <span v-if="editRfmHead != index">{{ item.valueName }}</span>
              <i class="el-icon-edit" @click.stop="editRfmHead = index"></i>
            </template>
            <template slot-scope="scope">
              <p>{{ item[scope.row.prop] }}</p>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
    <div class="bottom_btn">
      <el-button
        @click="submit"
        type="primary"
        :disabled="
          ruleForm.tagBizType != 'CUSTOM'
            ? false
            : labelValueList[currentIndex].customConditions.length >= 1
            ? false
            : true
        "
        >保存</el-button
      >
      <el-button @click="backRouter">取消</el-button>
    </div>
    <selectCreateWay ref="selectCreateWayRef" @selectData="selectData" />
    <selectTagVue
      ref="selectTagRef"
      @selectTags="selectTags"
      :tagTypeShow="false"
    ></selectTagVue>
    <selectUserVue ref="selectUserRef" @selectUser="selectUser"></selectUserVue>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { newGetTagGroup } from "@/api/labelManagement/index";
import { getCurrRecordList } from "@/api/labelManagement/index.js";
// import { getPreWebConfig } from "@/api/labelManagement/index";
import {
  getRulesConfig,
  addModifyCustomTag,
  addModifyRFMTag,
  getFormDetail,
  addOrModifyManualTag,
  // getTagGroup,
} from "@/api/labelManagement/newIndex.js";
import {
  getMain, // 获取主体
} from "@/api/wxData/index";
// import { deepClone } from "@/utils";
import selectCreateWay from "./components/selectCreateWay.vue";
import BehaviorRule from "./components/behaviorRule.vue";
import tagManageVue from "@/components/TagManage/index.vue";
import rfmVue from "@/components/TagManage/rfm.vue";
import CascaderFix from "@/components/cascaderFix.vue";
import selectTagVue from "../../userCenter/userList/components/selectTag.vue";
import selectUserVue from "@/components/selectUser.vue";

// import { xiaoeCouponList, getCurriculumList } from "@/api/hxl/public";
import {
  getCurrRecord,
  getGoodList,
  curriculumProject,
  getMaplist,
} from "../utilsQuery.js";
import { uniqueKey } from "@/utils/index.js";
let _this = null;
export default {
  name: "labelAdd",
  components: {
    draggable,
    selectCreateWay,
    BehaviorRule,
    tagManageVue,
    rfmVue,
    CascaderFix,
    selectTagVue,
    selectUserVue,
  },

  data() {
    // 自定义验证
    const validUpdateType = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择更新方式"));
      } else if (value == 3 && !this.ruleForm.timingInterval) {
        callback(new Error("请选择更新时间"));
      } else {
        callback();
      }
    };
    return {
      // workWechatGroupList: [], // 企微标签分组
      asyncWorkWxTag: true, // 同步企微标签开关
      groupList: [], //标签分组列表数据
      currentIndex: 0,
      customConditionsIndex: 0,
      labelValueList: [
        //customValues自定义标签值集
        {
          id: "", //标签id
          sort: "", //排序
          tagId: "", //标签id
          tagValueId: "", //标签值id
          valueName: "", //值名称
          customConditions: [],
          // configJson: { behaviors: undefined },
        },
      ],
      actionOptions: [
        {
          label: "做过",
          value: "EQUAL",
        },
        {
          label: "未做过",
          value: "NOT_EQUAL",
        },
      ],
      actionTypeOptions: [
        {
          label: "支付订单",
          value: "order",
        },
        {
          label: "学习课程",
          value: "study_course",
        },
        {
          label: "领取优惠券",
          value: "coupon_receive",
        },
        // {
        //   label: "到课",
        //   value: "learning_course",
        // },
        {
          label: "进入社群",
          value: "join_social_net",
        },
        {
          label: "进入直播间",
          value: "enter_live_room",
        },
        {
          label: "企微私聊",
          value: "chat_1",
        },
        {
          label: "社群互动",
          value: "chat_g",
        },
        // {
        //   label: "添加微信",
        //   value: "add_wechat",
        // }
      ],
      defaultLogicOptions: [
        { label: "等于", value: "EQUAL", disable: false },
        { label: "不等于", value: "NOT_EQUAL", disable: false },
        { label: "包含", value: "CONTAIN", disable: false },
        { label: "不包含", value: "NOT_CONTAIN", disable: false },
        { label: "为空", value: "EMPTY", disable: false },
        { label: "不为空", value: "NOT_EMPTY", disable: false },
      ],
      //标签值数据
      ruleForm: {
        id: "",
        tagName: "", //标签名称
        tagGroupId: "", //标签分组
        tagType: "",
        triggerMode: "MANUAL",
        timingInterval: null,
        timingWay: null, //定时方式(1每天)
        tagBizType: 0,
        hittingMode: 0, //规则命中方式 0=尽可能全匹配 2=按顺序只命中一个
        automaticActions: [
          {
            corpId: [],
            group: "",
            staffWxList: [],
          },
        ],
      },
      groupLoading: false,
      rfmFormData: {
        codeCondition: {
          //购买课程
          connector: "NOT",
          dataKey: "curriculumCode",
          dataType: "STRING",
          inputs: [],
          operator: "CONTAIN",
          keyMapping: "",
        },
        frequencyCondition: {
          //Frequency（消费频率）
          interval: [],
          judgeValueType: 0,
          result: true,
          resultOperator: "GREATER_EQUAL",
          resultType: 0,
          customComparison: "",
        },
        levelCondition: {
          //购买课程等级
          connector: "NOT",
          dataKey: "curriculumLevel",
          dataType: "STRING",
          inputs: [],
          operator: "CONTAIN",
          keyMapping: "",
        },
        monetaryCondition: {
          //Monetary（消费金额）
          interval: [],
          judgeValueType: 0,
          result: true,
          resultOperator: "GREATER_EQUAL",
          resultType: 0,
          customComparison: "",
        },
        projectCondition: {
          //购买课程项目
          connector: "NOT",
          dataKey: "curriculumProject",
          dataType: "STRING",
          inputs: [],
          operator: "CONTAIN",
          keyMapping: "",
        },
        recencyCondition: {
          //Recency（最近一次消费日期到今天的天数）
          interval: [],
          judgeValueType: 0,
          result: true,
          resultOperator: "LESS_EQUAL",
          resultType: 0,
          customComparison: "",
        },
        values: [
          {
            recencySign: "高",
            frequencySign: "高",
            monetarySign: "高",
            sort: 0,
            valueName: "高价值客户",
          },
          {
            recencySign: "低",
            frequencySign: "高",
            monetarySign: "高",
            sort: 1,
            valueName: "重点保持客户",
          },
          {
            recencySign: "高",
            frequencySign: "低",
            monetarySign: "高",
            sort: 2,
            valueName: "重点发展客户",
          },
          {
            recencySign: "低",
            frequencySign: "低",
            monetarySign: "高",
            sort: 3,
            valueName: "重点挽留客户",
          },
          {
            recencySign: "高",
            frequencySign: "高",
            monetarySign: "低",
            sort: 4,
            valueName: "一般价值客户",
          },
          {
            recencySign: "低",
            frequencySign: "高",
            monetarySign: "低",
            sort: 5,
            valueName: "一般保持客户",
          },
          {
            recencySign: "高",
            frequencySign: "低",
            monetarySign: "低",
            sort: 6,
            valueName: "一般发展客户",
          },
          {
            recencySign: "低",
            frequencySign: "低",
            monetarySign: "低",
            sort: 7,
            valueName: "潜在客户",
          },
        ], //标签值集合
        // status: "", //状态 0启用 1禁用
        // tagBizType: "RFM", //创建方式 0自定义 1计算 2RFM,可用值:MANUAL,RFM,CUSTOM
        // tagGroupId: "", //标签分组
        // tagName: "", //标签名称
        // triggerMode: "MANUAL", //执行方式(MANUAL 手动更新 REAL_TIME 实时更新 TIMING 定时更新),可用值:MANUAL,REAL_TIME,TIMING
        // id: "",
      },
      formDataRules: {
        tagName: [
          { required: true, message: "请输入标签名称", trigger: "blur" },
          { max: 30, message: "长度不超过30个字", trigger: "blur" },
        ],
        tagGroupId: [
          { required: true, message: "请选择标签分组", trigger: "change" },
        ],
        tagType: [
          { required: true, message: "请选择标签类型", trigger: "change" },
        ],
        triggerMode: [
          { required: true, trigger: "change", validator: validUpdateType },
        ],
        hittingMode: [
          { required: true, trigger: "change", message: "请选择命中规则" },
        ],
      },
      // behaviorData: null,
      firstRulesInputs: [],
      rulesConfig: [],
      rulesConfigAll: [],
      childRules: {},
      fatherIndex: 0,
      levelRule: [],
      projectRule: [],
      curriculumProps: {
        multiple: true,
        emitPath: false,
        value: "code",
      },
      rfmTableData: [
        {
          prop: "recencySign",
          label: "Recency（最近一次消费时间到今天的天数）",
        },
        { prop: "frequencySign", label: "Frequency（消费频率）" },
        { prop: "monetarySign", label: "Monetary（消费金额）" },
      ],
      editRfmHead: null,
      dataKeyOptions: [],
      dataKeyOptionsAll: [],
      operatorOptions: [
        { label: "等于", value: "EQUAL" },
        { label: "大于等于", value: "GREATER_EQUAL" },
        { label: "小于等于", value: "LESS_EQUAL" },
        { label: "区间", value: "BETWEEN" },
        { label: "大于", value: "GREATER" },
        { label: "小于", value: "LESS" },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              picker.$emit("pick", [start, end]);
              _this.pickerType[
                `${_this.currentIndex}-${_this.customConditionsIndex}`
              ] = this.text;
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", [start, end]);
              _this.pickerType[
                `${_this.currentIndex}-${_this.customConditionsIndex}`
              ] = this.text;
            },
          },
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
              _this.pickerType[
                `${_this.currentIndex}-${_this.customConditionsIndex}`
              ] = this.text;
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
              _this.pickerType[
                `${_this.currentIndex}-${_this.customConditionsIndex}`
              ] = this.text;
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
              _this.pickerType[
                `${_this.currentIndex}-${_this.customConditionsIndex}`
              ] = this.text;
            },
          },
          {
            text: "本月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * end.getDate());
              picker.$emit("pick", [start, end]);
              _this.pickerType[
                `${_this.currentIndex}-${_this.customConditionsIndex}`
              ] = this.text;
            },
          },
          {
            text: "本年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              const currentDate = new Date();
              const currentYear = currentDate.getFullYear();
              const startDate = new Date(currentYear, 0, 1); // 今年年初的日期
              start.setTime(
                start.getTime() - (start.getTime() - startDate.getTime())
              );
              picker.$emit("pick", [start, end]);
              _this.pickerType[
                `${_this.currentIndex}-${_this.customConditionsIndex}`
              ] = this.text;
            },
          },
          {
            text: "上线以来",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(new Date(2021, 5, 1, 0, 0, 0).getTime());
              picker.$emit("pick", [start, end]);
              _this.pickerType[
                `${_this.currentIndex}-${_this.customConditionsIndex}`
              ] = this.text;
            },
          },
        ],
      },
      pickerType: {}, // pickerOption类型
      tagManageUpload: "",
      curriculumCodeList: [],
      goodsList: [], //商品列表
      projectList: [], //项目列表
      dictData: {}, //字典数据
      xiaoeCoupons: [], //小鹅通优惠券列表
      curriculumList: [], //小鹅通课程
      rulseType: "", // 规则类型，用户行为，用户属性
      corpList: [], //主体列表
    };
  },
  watch: {},
  created() {
    _this = this;
    getCurrRecord().then((res) => {
      this.curriculumCodeList = res;
    });
    curriculumProject().then((res) => {
      this.projectList = res;
    });
    getMaplist().then((res) => {
      this.dictData = res;
    });
    getGoodList().then((res) => {
      this.goodsList = res;
    });
    // this.getAllData();
    this.getRulesConfig();
  },
  mounted() {
    this.getTagGroup();
    this.ruleForm.tagBizType = this.$route.query.createWay;
    if (this.$route.query.id) {
      this.getTagDetail();
    }
    document.addEventListener("click", function () {
      this.editRfmHead = null;
    });
    this.getCurrRecord();
    this.getCorpList();
  },
  methods: {
    // 控制用户属性动作数据
    defaultLogicOptionsDisabled(nowVal) {
      if (["5a", "qw_remark"].includes(nowVal)) {
        this.defaultLogicOptions.forEach((element) => {
          if (["NOT_CONTAIN", "EMPTY", "NOT_EMPTY"].includes(element.value)) {
            element.disable = true;
          }
        });
      } else {
        this.defaultLogicOptions.forEach((element) => {
          element.disable = false;
        });
      }
    },
    firstRulesInputsChange(index) {
      this.customConditionsIndex = index;
      this.$nextTick(() => {
        if (
          document.getElementsByClassName("end-date")[0] ||
          (!document.getElementsByClassName("end-date")[0] &&
            _this.pickerType[
              `${_this.currentIndex}-${_this.customConditionsIndex}`
            ] != "上线以来")
        ) {
          _this.pickerType[
            `${_this.currentIndex}-${_this.customConditionsIndex}`
          ] = null;
        }
      });
    },
    // 执行动作变更事件
    actionTypeChange(index, data, defaultData = null) {
      const activeType = {
        order: 0,
        study_course: 2,
        coupon_receive: 1,
        // learning_course: 4,
        join_social_net: 5,
        enter_live_room: 6,
        chat_1: 10,
        chat_g: 11,
      };
      this.rulesConfig[`${this.currentIndex}-${index}`] =
        this.rulesConfigAll.filter((item) => {
          return item.eventType == activeType[data] && item.keyType != "AGG";
        });
      this.dataKeyOptions[`${this.currentIndex}-${index}`] =
        this.dataKeyOptionsAll.filter((item) => {
          return item.eventType == activeType[data];
        });
      if (
        !["nestedTags", "5a", "qw_remark"].includes(
          this.labelValueList[this.currentIndex].customConditions[index]
            .firstRules[0].dataKey
        )
      ) {
        this.labelValueList[this.currentIndex].customConditions[
          index
        ].firstRules[1].inputs = [`${data}`];
      }
      if (!defaultData) {
        delete this.labelValueList[this.currentIndex].customConditions[index]
          .simpleTagRuleConditionDefinitions;
        if (
          this.labelValueList[this.currentIndex].customConditions[index]
            .aggregationTagRuleConditionDefinitions
        ) {
          this.labelValueList[this.currentIndex].customConditions[
            index
          ].aggregationTagRuleConditionDefinitions[0].dataKey = "";
          this.labelValueList[this.currentIndex].customConditions[
            index
          ].aggregationTagRuleConditionDefinitions[0].inputs = [];
        }
      } else {
        this.labelValueList[this.currentIndex].customConditions[
          index
        ].aggregationTagRuleConditionDefinitions[0].dataKey =
          defaultData.dataKey;
        this.labelValueList[this.currentIndex].customConditions[
          index
        ].aggregationTagRuleConditionDefinitions[0].inputs = defaultData.inputs;
      }
    },
    // 做过，未做过变更事件
    firstRules1OperatorChange(data, val, index) {
      this.customConditionsIndex = index;
      if (val === "NOT_EQUAL") {
        delete data.aggregationTagRuleConditionDefinitions;
        delete data.simpleTagRuleConditionDefinitions;
      } else {
        this.$set(data, "aggregationTagRuleConditionDefinitions", [
          {
            connector: "NOT",
            dataKey: "",
            dataType: "NUMERAL",
            inputs: [],
            keyMapping: "",
            keyType: "AGG",
            operator: "LESS_EQUAL",
          },
        ]);
        this.$set(data, "simpleTagRuleConditionDefinitions", []);
      }
    },
    getCurrRecord() {
      //获取课程数据
      getCurrRecordList({
        pageNumber: 1,
        pageSize: 1000,
      })
        .then((res) => {
          if (res.code == 200) {
            this.curriculumCodeList = res.data;
            // this.curriculumCodeList.forEach((item) => {
            //   if (item.children && item.children.length > 0) {
            //     item.children.forEach((child) => {
            //       child.children = null;
            //     });
            //   }
            // });
          }
        })
        .catch((err) => {});
    },
    getTagDetail() {
      getFormDetail(this.$route.query.id).then((res) => {
        if (res.code == 200) {
          this.ruleForm = res.data;
          this.asyncWorkWxTag = this.ruleForm.automaticActions.length
            ? true
            : false;
          this.ruleForm.triggerMode
            ? this.ruleForm.triggerMode
            : (this.ruleForm.triggerMode = "MANUAL");
          if (this.ruleForm.tagBizType == "CUSTOM") {
            this.labelValueList = res.data.customValues;
            this.labelValueList.forEach((item, i) => {
              item.customConditions.forEach((items, y) => {
                this.$set(this.firstRulesInputs, `${i}-${y}`, []);
                this.$set(this.dataKeyOptions, `${i}-${y}`, []);
                if (items.firstRules[0].inputs[1] === "") {
                  this.$set(
                    items.firstRules[0].inputs,
                    1,
                    new Date().toISOString().split("T")[0]
                  );
                  this.pickerType[`${i}-${y}`] = "上线以来";
                }
                // if (items.firstRules[0].dataKey === "nestedTags") {
                //   if (items.firstRules[0].inputs[0].length > 1) {
                //     console.log(items.firstRules[0].inputs)
                //     console.log(items.firstRules[0].inputs[0].split(","))
                //     items.firstRules[0].inputs =
                //       items.firstRules[0].inputs[0].split(",");
                //   } else {
                //     items.firstRules[0].inputs = [];
                //   }
                // }
                items.connector = item.customConditions[0].connector;
              });
            });
            this.labelValueList[0].customConditions.forEach(
              (items, towIndex) => {
                if (items.firstRules.length > 1) {
                  this.$set(
                    this.firstRulesInputs,
                    `${0}-${towIndex}`,
                    items.firstRules[1].inputs.toString()
                  );
                }
                this.actionTypeChange(
                  towIndex,
                  this.firstRulesInputs[`${0}-${towIndex}`],
                  items.aggregationTagRuleConditionDefinitions
                    ? items.aggregationTagRuleConditionDefinitions[0]
                    : null
                );
              }
            );
          } else if (this.ruleForm.tagBizType == "MANUAL") {
            this.labelValueList = res.data.valueLabels;
          } else {
            this.rfmFormData = res.data;
            this.rfmFormData.projectCondition.inputs =
              res.data.projectCondition.inputs.length &&
              res.data.projectCondition.inputs[0]
                ? res.data.projectCondition.inputs[0].split(",")
                : [];
            this.rfmFormData.levelCondition.inputs =
              res.data.levelCondition.inputs.length &&
              res.data.levelCondition.inputs[0]
                ? res.data.levelCondition.inputs[0].split(",")
                : [];
            this.rfmFormData.codeCondition.inputs =
              res.data.codeCondition.inputs.length &&
              res.data.codeCondition.inputs[0]
                ? res.data.codeCondition.inputs[0].split(",")
                : [];
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },
    //添加标签值
    addLabelValue() {
      if (this.ruleForm.tagBizType == "MANUAL") {
        delete this.labelValueList[0].id;
        delete this.labelValueList[0].sort;
        delete this.labelValueList[0].tagId;
        this.labelValueList.push({
          tagValueId: "",
          valueName: "",
        });
        return;
      }
      this.labelValueList.push({
        id: "",
        value: "",
        checkBehavior: [],
        customConditions: [
          {
            connector: "OR",
            id: "",
            aggregationTagRuleConditionDefinitions: [
              {
                connector: "NOT",
                dataKey: "",
                dataType: "NUMERAL",
                inputs: [],
                keyMapping: "",
                keyType: "AGG",
                operator: "LESS_EQUAL",
              },
            ],
            firstRules: [
              //头规则入参
              {
                connector: "NOT", //连接符,可用值:NOT,AND,OR
                dataKey: "eventTime", //数据键
                dataType: "DATE_TIME", //数据类型,可用值:NUMERAL,STRING,DATE_TIME
                inputs: [], //填写参数
                keyMapping: "event_time", //键映射
                keyType: "SIMPLE", //键类型 1单属性 2聚合属性
                operator: "BETWEEN", //操作符,可用值:GREATER,GREATER_EQUAL,LESS,LESS_EQUAL,EQUAL,NOT_EQUAL,CONTAIN,NOT_CONTAIN,BETWEEN
              },
              {
                connector: "NOT", //连接符,可用值:NOT,AND,OR
                dataKey: "eventKey", //数据键
                dataType: "STRING", //数据类型,可用值:NUMERAL,STRING,DATE_TIME
                inputs: [], //填写参数
                keyMapping: "event_key", //键映射
                keyType: "SIMPLE", //键类型 1单属性 2聚合属性
                operator: "EQUAL", //操作符,可用值:GREATER,GREATER_EQUAL,LESS,LESS_EQUAL,EQUAL,NOT_EQUAL,CONTAIN,NOT_CONTAIN,BETWEEN
              },
            ],
            simpleTagRuleConditionDefinitions: [
              // {
              //   connector: "",
              //   dataKey: "",
              //   dataType: "",
              //   inputs: "",
              //   keyMapping: "",
              //   keyType:  'AGG',
              //   operator: "",
              // },
            ],
            tagValueId: "",
          },
        ],
      });
      this.currentIndex = this.labelValueList.length - 1;
      this.$nextTick(() => {
        let index = this.$refs.inputRef.length - 1;
        this.$refs.inputRef[index].focus(this.currentIndex);
        this.tagManageUpload = new Date().getTime();
      });
    },
    //删除标签
    deleteLabelValue(index) {
      if (this.labelValueList.length <= 1) return;
      this.labelValueList.splice(index, 1);
      this.currentIndex = this.labelValueList.length - 1;
      this.tagManageUpload = new Date().getTime();
    },
    clickInput(index) {
      if (!this.ruleForm.id) {
        return false;
      }
      this.currentIndex = index;
      this.tagManageUpload = new Date().getTime();
      if (this.labelValueList[index]) {
        this.labelValueList[index].customConditions.forEach(
          (items, towIndex) => {
            this.$set(
              this.firstRulesInputs,
              `${index}-${towIndex}`,
              items.firstRules[1].inputs.toString()
            );
            // this.firstRulesInputs[`${index}-${towIndex}`] =
            //   itmes.firstRules[1].inputs.toString();
            this.actionTypeChange(
              towIndex,
              this.firstRulesInputs[`${index}-${towIndex}`],
              items.aggregationTagRuleConditionDefinitions
                ? items.aggregationTagRuleConditionDefinitions[0]
                : null
            );
          }
        );
      }
    },
    //输入框获得焦点
    focus(index) {
      this.currentIndex = index;
      this.tagManageUpload = new Date().getTime();
      if (!this.ruleForm.id) {
        return false;
      }
      if (this.labelValueList[index]) {
        this.labelValueList[index].customConditions.forEach(
          (items, towIndex) => {
            this.$set(
              this.firstRulesInputs,
              `${index}-${towIndex}`,
              items.firstRules[1].inputs.toString()
            );
            // this.firstRulesInputs[`${index}-${towIndex}`] =
            //   itmes.firstRules[1].inputs.toString();
            this.actionTypeChange(
              towIndex,
              this.firstRulesInputs[`${index}-${towIndex}`],
              items.aggregationTagRuleConditionDefinitions
                ? items.aggregationTagRuleConditionDefinitions[0]
                : null
            );
          }
        );
      }
    },
    //获取标签分组
    getTagGroup() {
      let params = {
        pageNumber: 1,
        pageSize: 100,
        category: 0,
      };
      newGetTagGroup(params).then((res) => {
        if (res.code == 200) {
          this.groupList = res.data;
          this.checkChildren(this.groupList);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    checkChildren(data) {
      data.forEach((item) => {
        if (item.children.length === 0) {
          item.children = null;
        } else {
          this.checkChildren(item.children);
        }
      });
    },
    backRouter() {
      this.$router.back(-1);
    },
    submit() {
      let saveData;
      if (this.ruleForm.tagBizType == "CUSTOM") {
        if (this.ruleForm.automaticActions[0].corpId.length < 1) {
          this.$message.error("请完善同步企微标签数据！");
          return;
        }
        if (this.ruleForm.automaticActions[0].group.length < 1) {
          this.$message.error("请完善同步企微标签数据！");
          return;
        }
        if (this.ruleForm.automaticActions[0].staffWxList.length < 1) {
          this.$message.error("请完善同步企微标签数据！");
          return;
        }
        let validVal = this.validateFun();
        if (!validVal) return;
        this.ruleForm.customValues = this.labelValueList;
        this.ruleForm.customValues.forEach((item, i) => {
          item.customConditions.forEach((items, y) => {
            if (
              this.pickerType[`${i}-${y}`] &&
              !["nestedTags", "5a", "qw_remark"].includes(
                items.firstRules[0].dataKey
              )
            ) {
              items.firstRules[0].inputs[1] = "";
            }
            // if (items.firstRules[0].dataKey === "nestedTags") {
            //   items.firstRules[0].inputs = [
            //     items.firstRules[0].inputs.toString(),
            //   ];
            // }
          });
        });
        saveData = addModifyCustomTag;
      } else if (this.ruleForm.tagBizType == "MANUAL") {
        this.labelValueList.forEach((item) => {
          delete item.customConditions;
        });
        for (let item of this.labelValueList) {
          if (
            item.valueName == null ||
            item.valueName == undefined ||
            item.valueName == ""
          ) {
            this.$message.error("请填写标签值");
            return false;
          }
        }
        this.ruleForm.valueLabels = this.labelValueList;
        saveData = addOrModifyManualTag;
      } else {
        let validVal = this.validateRfm();
        if (!validVal) return;
        this.ruleForm = JSON.parse(
          JSON.stringify({ ...this.ruleForm, ...this.rfmFormData })
        );
        this.ruleForm.levelCondition.inputs = this.ruleForm.levelCondition
          .inputs.length
          ? [this.ruleForm.levelCondition.inputs.join(",")]
          : [];

        this.ruleForm.projectCondition.inputs = this.ruleForm.projectCondition
          .inputs.length
          ? [this.ruleForm.projectCondition.inputs.join(",")]
          : [];
        this.ruleForm.codeCondition.inputs = this.ruleForm.codeCondition.inputs
          .length
          ? [this.ruleForm.codeCondition.inputs.join(",")]
          : [];
        saveData = addModifyRFMTag;
      }
      this.$refs["ruleFormRef"].validate((valid) => {
        if (valid) {
          saveData(this.ruleForm).then((res) => {
            if (res.code == 200) {
              this.$message.success(res.message);
              this.$router.back(-1);
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    // 自定义标签数据验证
    validateFun() {
      if (!this.labelValueList.length) {
        this.$message.error("请添加标签值");
        return false;
      }
      for (let item of this.labelValueList) {
        if (
          item.valueName == null ||
          item.valueName == undefined ||
          item.valueName == ""
        ) {
          this.$message.error("请填写标签值");
          return false;
        }
        for (let customConditions of item.customConditions) {
          if (customConditions.aggregationTagRuleConditionDefinitions) {
            for (let items of customConditions.aggregationTagRuleConditionDefinitions) {
              for (let key in items) {
                if (
                  (items[key] == null ||
                    items[key] == undefined ||
                    items[key].toString() == "") &&
                  key != "keyMapping"
                ) {
                  console.log(items, key);
                  this.$message.error("请完整填写标签数据1");
                  return false;
                }
              }
            }
          }
          for (let items of customConditions.firstRules) {
            if (
              items.dataKey === "nestedTags" &&
              ["EMPTY", "NOT_EMPTY"].includes(items.operator)
            ) {
              return true;
            }
            for (let key in items) {
              if (
                items[key] == null ||
                items[key] == undefined ||
                items[key].toString() == ""
              ) {
                this.$message.error("请完整填写标签数据2");
                return false;
              }
            }
          }
          if (
            customConditions.simpleTagRuleConditionDefinitions &&
            customConditions.simpleTagRuleConditionDefinitions.length
          ) {
            for (let items of customConditions.simpleTagRuleConditionDefinitions) {
              for (let key in items) {
                if (
                  items[key] == null ||
                  items[key] == undefined ||
                  items[key].toString() == ""
                ) {
                  console.log(
                    customConditions.simpleTagRuleConditionDefinitions,
                    key
                  );
                  this.$message.error("请完整填写二级标签数据");
                  return false;
                }
              }
            }
          }
        }
      }
      return true;
    },
    // RFM数据验证
    validateRfm() {
      let keys = [
        "recencyCondition",
        "frequencyCondition",
        "monetaryCondition",
      ];
      const key = Object.keys(this.rfmFormData);
      for (let i in key) {
        if (keys.includes(key[i])) {
          const childKey = Object.keys(this.rfmFormData[key[i]]);
          for (let y in childKey) {
            if (this.rfmFormData[key[i]].judgeValueType == 1) {
              if (
                (this.rfmFormData[key[i]][childKey[y]] == null ||
                  this.rfmFormData[key[i]][childKey[y]] == undefined ||
                  this.rfmFormData[key[i]][childKey[y]].toString() == "") &&
                childKey[y] != "customComparison"
              ) {
                this.$message.error("请完善标签取值内容！");
                return false;
              }
            }
            if (
              this.rfmFormData[key[i]].resultType == 1 &&
              (this.rfmFormData[key[i]].customComparison == null ||
                this.rfmFormData[key[i]].customComparison == undefined ||
                this.rfmFormData[key[i]].customComparison.toString() == "")
            ) {
              this.$message.error("请完善标签计算规则内容！");
              return false;
            }
          }
          if (this.rfmFormData[key[i]].interval.toString != "") {
            for (let z in this.rfmFormData[key[i]].interval) {
              const values = this.rfmFormData[key[i]].interval[z].split(",");
              if (values[0] == "" || values[1] == "" || values[2] == "") {
                this.$message.error("请完善标签取值内容！");
                return false;
              }
            }
          }
        }
      }
      return true;
    },
    //切换创建方式
    selectCreateWay() {
      this.$refs.selectCreateWayRef.isShowCreateWay = true;
      this.$refs.selectCreateWayRef.currentIndex = this.ruleForm.tagBizType;
    },
    backRouter() {
      this.$router.back(-1);
    },
    selectData(data) {
      this.ruleForm.tagBizType = data;
      // this.$set(this.ruleForm, "triggerMode", data);
    },
    // 获取规则配置
    getRulesConfig() {
      getRulesConfig({ creatWay: this.ruleForm.tagBizType })
        .then((res) => {
          if (res.code == 200) {
            this.rulesConfigAll = res.data;
            if (this.ruleForm.tagBizType == "MANUAL") {
              this.actionTypeOptions = res.data;
            }
            this.rulesConfig[`${this.currentIndex}-0`] = res.data.filter(
              (item) => {
                return item.keyType != "AGG";
              }
            );
            this.dataKeyOptionsAll = res.data.filter((item) => {
              return item.keyType == "AGG";
            });
          } else {
            this.$message.error(res.message);
          }
        })
        .catch((err) => {});
    },
    // 新增父级条件
    addFather(str) {
      const connector = this.labelValueList[this.currentIndex]
        .customConditions[0]
        ? this.labelValueList[this.currentIndex].customConditions[0].connector
        : "OR";
      this.rulseType = str;
      this.firstRulesInputs[
        `${this.currentIndex}-
        ${this.labelValueList[this.currentIndex].customConditions.length - 1}`
      ];
      if (str === "behavior") {
        this.labelValueList[this.currentIndex].customConditions.push({
          connector: connector,
          id: "",
          aggregationTagRuleConditionDefinitions: [
            {
              connector: "NOT",
              dataKey: "",
              dataType: "NUMERAL",
              inputs: [],
              keyMapping: "",
              keyType: "AGG",
              operator: "LESS_EQUAL",
            },
          ],
          firstRules: [
            //头规则入参
            {
              connector: "NOT", //连接符,可用值:NOT,AND,OR
              dataKey: "eventTime", //数据键
              dataType: "DATE_TIME", //数据类型,可用值:NUMERAL,STRING,DATE_TIME
              inputs: [], //填写参数
              keyMapping: "event_time", //键映射
              keyType: "SIMPLE", //键类型 1单属性 2聚合属性
              operator: "BETWEEN", //操作符,可用值:GREATER,GREATER_EQUAL,LESS,LESS_EQUAL,EQUAL,NOT_EQUAL,CONTAIN,NOT_CONTAIN,BETWEEN
            },
            {
              connector: "NOT", //连接符,可用值:NOT,AND,OR
              dataKey: "eventKey", //数据键
              dataType: "STRING", //数据类型,可用值:NUMERAL,STRING,DATE_TIME
              inputs: [], //填写参数
              keyMapping: "event_key", //键映射
              keyType: "SIMPLE", //键类型 1单属性 2聚合属性
              operator: "EQUAL", //操作符,可用值:GREATER,GREATER_EQUAL,LESS,LESS_EQUAL,EQUAL,NOT_EQUAL,CONTAIN,NOT_CONTAIN,BETWEEN
            },
          ],
          simpleTagRuleConditionDefinitions: [
            // {
            //   connector: "",
            //   dataKey: "",
            //   dataType: "",
            //   inputs: "",
            //   keyMapping: "",
            //   keyType:  'AGG',
            //   operator: "",
            // },
          ],
          tagValueId: "",
        });
      }
      if (str === "stats") {
        this.labelValueList[this.currentIndex].customConditions.push({
          connector: connector,
          id: "",
          firstRules: [
            //头规则入参
            {
              connector: "NOT", //连接符,可用值:NOT,AND,OR
              dataKey: "nestedTags", //数据键
              dataType: "DATE_TIME", //数据类型,可用值:NUMERAL,STRING,DATE_TIME
              inputs: [], //填写参数
              keyMapping: "event_time", //键映射
              keyType: "SIMPLE", //键类型 1单属性 2聚合属性
              operator: "", //操作符,可用值:GREATER,GREATER_EQUAL,LESS,LESS_EQUAL,EQUAL,NOT_EQUAL,CONTAIN,NOT_CONTAIN,BETWEEN
            },
          ],
          tagValueId: "",
        });
      }
      this.actionTypeChange(
        this.labelValueList[this.currentIndex].customConditions.length - 1,
        this.firstRulesInputs[
          `${this.currentIndex}-${
            this.labelValueList[this.currentIndex].customConditions.length - 1
          }`
        ]
      );
    },
    // 新增子条件
    addChild(data, i) {
      this.fatherIndex = i;
      if (
        data.simpleTagRuleConditionDefinitions &&
        data.simpleTagRuleConditionDefinitions.length
      ) {
        data.simpleTagRuleConditionDefinitions.push({
          connector: data.simpleTagRuleConditionDefinitions[0].connector,
          dataKey: undefined,
          dataType: undefined,
          inputs: [],
          operator: undefined,
          keyType: "SIMPLE",
          keyMapping: undefined,
        });
      } else {
        // this.childRules[i] = [];
        this.$set(data, "simpleTagRuleConditionDefinitions", [
          {
            connector: "OR",
            dataKey: undefined,
            dataType: undefined,
            inputs: [],
            operator: undefined,
            keyType: "SIMPLE",
            keyMapping: undefined,
          },
        ]);
      }
    },
    // 删除条件
    delRules(type, fatherIndex, i = null) {
      console.log(this.labelValueList[this.currentIndex].customConditions);
      if (type === "father") {
        if (
          this.labelValueList[this.currentIndex].customConditions[fatherIndex]
        ) {
          this.labelValueList[this.currentIndex].customConditions.splice(
            fatherIndex,
            1
          );
        }
        delete this.firstRulesInputs[`${this.currentIndex}-${fatherIndex}`];
      } else {
        this.labelValueList[this.currentIndex].customConditions[
          fatherIndex
        ].simpleTagRuleConditionDefinitions.splice(i, 1);
      }
      this.tagManageUpload = new Date().getTime();
    },
    // 获取组件值
    changeData(data) {
      console.log("changeData:", data.newVal);
      const { sourceData, newVal } = data;
      const { type, index, fatherIndex, childIndex } = sourceData;
      const {
        typeValue,
        config,
        value1,
        inputValue,
        logicValue,
        keyType,
        couponTypeVal,
      } = newVal;
      const { dataType, keyMapping } = config;
      const condition =
        type === "father"
          ? this.labelValueList[this.currentIndex].customConditions[index]
              .firstRules[1]
          : this.labelValueList[this.currentIndex].customConditions[fatherIndex]
              .simpleTagRuleConditionDefinitions[childIndex];
      condition.dataKey = typeValue;
      condition.dataType = dataType;
      condition.keyMapping = keyMapping;
      condition.keyType = keyType;
      condition.operator = logicValue;
      if (type !== "father") {
        // 此处Key应与子组件tagManage/index.vue中changeData的key相同
        const keys = [
          "purchaseProductVal",
          "curriculumLevelVal",
          "curriculumProjectVal",
          "value1",
          "inputValue",
          "cascaderValue",
          "couponVal",
          "resourceId",
          "couponTypeVal",
          "learningCourse",
          "clickGoods",
          "enterLive",
          "socialNetName",
          "wxGroupName",
          "wxOwner",
          "wechat",
        ];

        for (let key in keys) {
          if (
            newVal[keys[key]] === null ||
            newVal[keys[key]] === undefined ||
            newVal[keys[key]] === "" ||
            newVal[keys[key]].toString() === ""
          ) {
            condition.inputs = [];
          } else {
            console.log(keys[key], newVal[keys[key]]);
            if (["inputValue"].includes(keys[key])) {
              condition.inputs = [newVal[keys[key]]];
            } else {
              condition.inputs = [newVal[keys[key]].toString()];
            }
            console.log("changeData-condition-change:", condition);
            return;
          }
        }
        if (newVal.minMoneyValue != "") {
          condition.inputs[0] = newVal.minMoneyValue;
        }
        if (newVal.maxMoneyValue != "") {
          condition.inputs[1] = newVal.maxMoneyValue;
        }
      } else {
        condition.inputs = typeValue == "event_time" ? value1 : [inputValue];
      }
    },
    // 更改最外层且或状态
    fatherConnectorChange() {
      const connector =
        this.labelValueList[this.currentIndex].customConditions[0].connector;
      this.labelValueList[this.currentIndex].customConditions.forEach(
        (item) => {
          item.connector = connector == "AND" ? "OR" : "AND";
        }
      );
    },
    // 更改内层且或状态
    childSimpleChange(data) {
      data.simpleTagRuleConditionDefinitions.forEach((item) => {
        item.connector == "AND"
          ? (item.connector = "OR")
          : (item.connector = "AND");
      });
    },
    // AGGdataKey变更时
    aggDataKeyChange(val) {
      console.log(
        "aggDataKeyChange:",
        this.dataKeyOptions[[`${this.currentIndex}-${val}`]],
        val
      );
      this.dataKeyOptions[[`${this.currentIndex}-${val}`]].forEach((item) => {
        if (
          item.key ==
          this.labelValueList[this.currentIndex].customConditions[val]
            .aggregationTagRuleConditionDefinitions[0].dataKey
        ) {
          this.labelValueList[this.currentIndex].customConditions[
            val
          ].aggregationTagRuleConditionDefinitions[0].dataType = item.dataType;
          this.labelValueList[this.currentIndex].customConditions[
            val
          ].aggregationTagRuleConditionDefinitions[0].keyMapping =
            item.keyMapping;
        }
      });
      console.log(
        this.labelValueList[this.currentIndex].customConditions[val]
          .aggregationTagRuleConditionDefinitions
      );
    },
    // 获取rfm组件值
    intervalFun(data) {
      switch (data.type) {
        case "R":
          this.rfmFormData.recencyCondition.interval = [];
          this.$set(
            this.rfmFormData.recencyCondition,
            "judgeValueType",
            data.judgeValueType
          );
          this.$set(
            this.rfmFormData.recencyCondition,
            "resultType",
            data.resultType
          );
          this.$set(
            this.rfmFormData.recencyCondition,
            "customComparison",
            data.customComparison
          );
          if (data.judgeValueType) {
            for (let i in data.interval) {
              if (i > 0 && i < data.interval.length - 1) {
                this.rfmFormData.recencyCondition.interval.push(
                  `${
                    data.interval[i].rangeStart
                      ? data.interval[i].rangeStart
                      : data.interval[i - 1].rangeEnd
                  },${data.interval[i].rangeEnd},${data.interval[i].rangeValue}`
                );
              } else {
                this.rfmFormData.recencyCondition.interval.push(
                  `${data.interval[i].rangeEnd},${data.interval[i].rangeValue}`
                );
              }
            }
          }
          break;
        case "F":
          this.rfmFormData.frequencyCondition.interval = [];
          this.$set(
            this.rfmFormData.frequencyCondition,
            "judgeValueType",
            data.judgeValueType
          );
          this.$set(
            this.rfmFormData.frequencyCondition,
            "resultType",
            data.resultType
          );
          this.$set(
            this.rfmFormData.frequencyCondition,
            "customComparison",
            data.customComparison
          );
          if (data.judgeValueType) {
            for (let i in data.interval) {
              if (i > 0 && i < data.interval.length - 1) {
                this.rfmFormData.frequencyCondition.interval.push(
                  `${
                    data.interval[i].rangeStart
                      ? data.interval[i].rangeStart
                      : data.interval[i - 1].rangeEnd
                  },${data.interval[i].rangeEnd},${data.interval[i].rangeValue}`
                );
              } else {
                this.rfmFormData.frequencyCondition.interval.push(
                  `${data.interval[i].rangeEnd},${data.interval[i].rangeValue}`
                );
              }
            }
          }
          break;
        default:
          this.rfmFormData.monetaryCondition.interval = [];
          this.$set(
            this.rfmFormData.monetaryCondition,
            "judgeValueType",
            data.judgeValueType
          );
          this.$set(
            this.rfmFormData.monetaryCondition,
            "resultType",
            data.resultType
          );
          this.$set(
            this.rfmFormData.monetaryCondition,
            "customComparison",
            data.customComparison
          );
          if (data.judgeValueType) {
            for (let i in data.interval) {
              if (i > 0 && i < data.interval.length - 1) {
                this.rfmFormData.monetaryCondition.interval.push(
                  `${
                    data.interval[i].rangeStart
                      ? data.interval[i].rangeStart
                      : data.interval[i - 1].rangeEnd
                  },${data.interval[i].rangeEnd},${data.interval[i].rangeValue}`
                );
              } else {
                this.rfmFormData.monetaryCondition.interval.push(
                  `${data.interval[i].rangeEnd},${data.interval[i].rangeValue}`
                );
              }
            }
          }
          break;
      }
    },
    curriculumLevelChange() {
      let levelConditionConfig = this.rulesConfigAll.filter((item) => {
        return item.key === "curriculumLevel";
      })[0];
      delete levelConditionConfig.operatorScope;
      this.rfmFormData.levelCondition = {
        ...this.rfmFormData.levelCondition,
        ...levelConditionConfig,
      };
    },
    curriculumProjectChange() {
      let projectConditionConfig = this.rulesConfigAll.filter((item) => {
        return item.key === "curriculumProject";
      })[0];
      delete projectConditionConfig.operatorScope;
      this.rfmFormData.projectCondition = {
        ...this.rfmFormData.projectCondition,
        ...projectConditionConfig,
      };
    },
    curriculumCodeChange() {
      let codeConditionConfig = this.rulesConfigAll.filter((item) => {
        return item.key === "curriculumCode";
      })[0];
      delete codeConditionConfig.operatorScope;
      this.rfmFormData.codeCondition = {
        ...this.rfmFormData.codeCondition,
        ...codeConditionConfig,
      };
    },
    // 获取子集数据
    // getAllData() {
    // getGoodList().then((res) => {
    //   this.goodsList = res;
    // });
    // xiaoeCouponList().then((res) => {
    //   this.xiaoeCoupons = res.data;
    // });
    // getCurriculumList().then((res) => {
    //   this.curriculumList = res.data;
    // });
    // },
    // oepneTags打开选择标签弹窗
    oepneTags(data, val, index) {
      this.customConditionsIndex = index;
      this.$refs.selectTagRef.selectTagList = [];
      this.$refs.selectTagRef.visibleDialog = true;
      this.$refs.selectTagRef.tagType = 2;
      this.$refs.selectTagRef.getTagGroup();
      this.$refs.selectTagRef.getTagListData();
      this.$refs.selectTagRef.selectTagList = JSON.parse(JSON.stringify(val));
      // for (let i in val) {
      //   this.$refs.selectTagRef.selectTagList.push(val[i].tagId);
      // }
    },
    // 获取已选标签值
    selectTags(val) {
      this.labelValueList[this.currentIndex].customConditions[
        this.customConditionsIndex
      ].firstRules[0].inputs = [];
      val.selectTagList.forEach((item) => {
        this.labelValueList[this.currentIndex].customConditions[
          this.customConditionsIndex
        ].firstRules[0].inputs.push(
          typeof item === "object" ? item.tagId.toString() : item
        );
      });
    },
    // 选择企微成员
    selectWorkWx() {
      this.$refs.selectUserRef.selectUserShow = true;
      this.$nextTick(() => {
        // this.$refs.selectUserRef.userList =
        //   this.ruleForm.automaticActions[0].staffWxList;
        this.$refs.selectUserRef.$refs.tree.setCheckedKeys([]);
        this.$refs.selectUserRef.getUserWatch();
        this.ruleForm.automaticActions[0].staffWxList.forEach((item) => {
          this.$refs.selectUserRef.$refs.tree.setChecked(item, true);
        });
      });
    },
    // 选择微信
    selectUser(val) {
      this.ruleForm.automaticActions[0].corpId = [];
      this.ruleForm.automaticActions[0].staffWxList = [];
      this.ruleForm.automaticActions[0].corpId = val.corpList;
      val.userList.forEach((item) => {
        this.ruleForm.automaticActions[0].staffWxList.push(item.userId);
        if (val.corpList.length < 1) {
          this.ruleForm.automaticActions[0].corpId.push(item.corpId);
        }
      });
      this.ruleForm.automaticActions[0].corpId = [
        ...new Set(this.ruleForm.automaticActions[0].corpId),
      ];
    },
    // 获取主体
    getCorpList() {
      getMain().then((res) => {
        this.corpList = res;
        this.corpList.unshift({ corpId: "", enterprise: "全部" });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  margin-bottom: 20px;
  padding: 20px 30px 0;
  color: #333;

  .tab_bar {
    position: relative;
    font-size: 18px;
    color: #333333;
    margin-bottom: 20px;
    padding-left: 10px;
  }

  .tab_bar::before {
    content: "";
    position: absolute;
    left: 0;
    top: -1px;
    bottom: 3px;
    border-left: 3px solid #007979;
  }

  .base_info {
    padding-left: 10px;
    margin-bottom: 0px;

    p {
      margin: 0;
      // margin-left: 68px;
      color: #c9c9c9;
      line-height: 20px;
    }

    .label_box {
      display: flex;

      .el-input {
        width: 250px;
        margin-right: 10px;
        margin-bottom: 10px;
      }

      ::v-deep .el-input__inner {
        cursor: pointer;
      }
    }
  }

  .tagContent {
    position: relative;
    display: flex;
    padding: 10px;
    border: 1px solid #e7eaec;

    .divider {
      position: relative;
      left: 0;
      top: 0;
      bottom: 0;
      margin-right: 8px;
      margin-bottom: 10px;

      span {
        position: relative;
        display: block;
        top: 50%;
        left: 0;
        right: 0;
        width: auto;
        min-width: 25px;
        height: 25px;
        transform: translateY(-50%);
        border-radius: 3px;
        background: #1890ff;
        color: #ffffff;
        font-size: 14px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        cursor: pointer;
        user-select: none;
      }
    }

    .divider::before {
      content: " ";
      position: absolute;
      top: 0;
      left: 50%;
      width: 2px;
      height: 100%;
      overflow: hidden;
      background: #1890ff;
      box-sizing: border-box;
    }

    .divider_non::before {
      content: " ";
      background: #ffffff;
    }

    .divider_non {
      span {
        background: #ffffff;
      }
    }

    .tagBox {
      // display: flex;
      width: 100%;

      .contentRight {
        width: 100%;

        .fatherBox {
          display: flex;
          width: 100%;

          .conditionNumBox {
            padding: 5px;
            min-width: 50px;
            color: #c9c9c9;
          }

          .fatherContent {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .el-select,
            .el-cascader,
            .el-input {
              margin-right: 10px;
            }
            .tagSelect {
              display: inline-block;
            }
          }
        }

        .childBox {
          display: flex;
          width: 100%;

          .childHint {
            width: 50px;
            color: #1890ff;
          }

          .childContentRight {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 40px;
          }
        }

        .rightBox {
          display: flex;
          justify-content: flex-end;
          padding: 10px;
          width: 160px;
        }
      }
    }

    .tagfooter {
      position: absolute;
      bottom: 0;
      right: 10px;
      display: flex;
      justify-content: flex-end;
    }
  }
  .addBtn {
    margin-top: 10px;
  }

  .bottom_btn {
    // position: fixed;
    // bottom: 0px;
    // right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
    // width: calc(100% - 205px);
    background: #ffffff;
    border-top: 1px solid #e7eaec;
  }

  .inputFocus {
    outline: none;
    border: 1px solid #1890ff;
    border-radius: 5px;

    ::v-deep.el-input__inner {
      border: none !important;
    }
  }

  .last-check {
    li[aria-haspopup="true"] {
      .el-checkbox {
        display: none;
      }
    }
  }
}

::v-deep .el-table thead.is-group th {
  background: none;
}

::v-deep .el-table thead.is-group tr:first-of-type th:first-of-type {
  border-bottom: none;
}

::v-deep .el-table thead.is-group tr:first-of-type th:first-of-type:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 120px;
  /*这里需要自己调整，根据td的宽度和高度*/
  top: 0;
  left: 0;
  background-color: #bbb;
  opacity: 0.3;
  display: block;
  transform: rotate(-58deg);
  /*这里需要自己调整，根据线的位置*/
  transform-origin: top;
}

::v-deep .el-table thead.is-group tr:last-of-type th:first-of-type:before {
  content: "";
  position: absolute;
  width: 1px;
  height: 120px;
  /*这里需要自己调整，根据td的宽度和高度*/
  bottom: 0;
  right: 0;
  background-color: #bbb;
  opacity: 0.3;
  display: block;
  transform: rotate(-59.9deg);
  /*这里需要自己调整，根据线的位置*/
  transform-origin: bottom;
}
</style>
