var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main order", attrs: { id: "main" } },
    [
      _c(
        "div",
        { staticClass: "btn_top" },
        [
          _c(
            "el-form",
            { attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "创建开始时间",
                      "end-placeholder": "创建结束时间",
                      "unlink-panels": "",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.date,
                      callback: function ($$v) {
                        _vm.date = $$v
                      },
                      expression: "date",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请选择标签类型", clearable: "" },
                      model: {
                        value: _vm.params.tagBizType,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "tagBizType", $$v)
                        },
                        expression: "params.tagBizType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "自定义模型标签", value: "CUSTOM" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "动态标签", value: "CALCULATE" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "RFM标签", value: "RFM" },
                      }),
                      _vm._v(" "),
                      _c("el-option", {
                        attrs: { label: "手动标签", value: "MANUAL" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "200px" },
                      attrs: { placeholder: "请选择标签状态", clearable: "" },
                      model: {
                        value: _vm.params.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "status", $$v)
                        },
                        expression: "params.status",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "启用", value: 0 } }),
                      _vm._v(" "),
                      _c("el-option", { attrs: { label: "禁用", value: 1 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleSearch },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "permission",
                          rawName: "v-permission",
                          value: ["labelManagement:add"],
                          expression: "['labelManagement:add']",
                        },
                      ],
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addLabel()
                        },
                      },
                    },
                    [_vm._v("创建标签")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-form-item",
                [
                  _c("filter-column", {
                    attrs: { columns: _vm.columns },
                    on: {
                      "update:columns": function ($event) {
                        _vm.columns = $event
                      },
                      refresh: _vm.getList,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.importUser } },
                [_vm._v("导入用户")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "content_box" }, [
        _c(
          "div",
          { staticClass: "group_box" },
          [
            _c(
              "el-radio-group",
              {
                staticStyle: { width: "100%" },
                attrs: { size: "medium" },
                on: { change: _vm.getTagGroup },
                model: {
                  value: _vm.tagType,
                  callback: function ($$v) {
                    _vm.tagType = $$v
                  },
                  expression: "tagType",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: 1, size: "medium" } }, [
                  _c("div", { staticClass: "tagTypeStyle" }, [
                    _vm._v("系统标签"),
                  ]),
                ]),
                _vm._v(" "),
                _c("el-radio-button", { attrs: { label: 0, size: "medium" } }, [
                  _c("div", { staticClass: "tagTypeStyle" }, [
                    _vm._v("自定义标签"),
                  ]),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "list_view" }, [
              _vm.tagType == 0
                ? _c(
                    "div",
                    {
                      staticClass: "add_box",
                      on: {
                        click: function ($event) {
                          return _vm.addGroup(null, 1)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus" }),
                      _vm._v(" "),
                      _c("span", [_vm._v("添加分组")]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "list_box" },
                [
                  _c("el-tree", {
                    attrs: {
                      data: _vm.groupList,
                      "node-key": "id",
                      "default-expand-all": "",
                      draggable: "",
                      "allow-drop": _vm.allowDrop,
                      "allow-drag": _vm.allowDrag,
                      props: {
                        label: "name",
                      },
                    },
                    on: {
                      "node-drag-end": _vm.handleDragEnd,
                      "node-drop": _vm.handleDrop,
                      "node-click": _vm.treeNodeClick,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var node = ref.node
                          var data = ref.data
                          return _c(
                            "span",
                            { staticClass: "custom-tree-node" },
                            [
                              _c("span", [_vm._v(_vm._s(node.label))]),
                              _vm._v(" "),
                              data.id != "" && data.isPreset != 0
                                ? _c(
                                    "span",
                                    [
                                      _vm.tagType != 1
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addGroup(data, 2)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-edit",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      (
                                        _vm.tagType != 1
                                          ? node.level != 3
                                          : false
                                      )
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addGroup(data, 1)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-circle-plus-outline",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.tagType != 1
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "text",
                                                size: "mini",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteGroup(data)
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-delete",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          )
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "table_box" },
          [
            _c("el-s-table", {
              attrs: { columns: _vm.columns, data: _vm.tableData, border: "" },
              scopedSlots: _vm._u([
                {
                  key: "status",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c("div", { staticClass: "status" }, [
                        _c("span", { class: row.status == 1 ? "waring" : "" }),
                        _vm._v(
                          "\n            " +
                            _vm._s(!row.status ? "启用" : "禁用") +
                            "\n          "
                        ),
                      ]),
                    ]
                  },
                },
                {
                  key: "default",
                  fn: function (ref) {
                    var row = ref.row
                    return [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: ["labelManagement:update"],
                              expression: "['labelManagement:update']",
                            },
                          ],
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.synchronization(row)
                            },
                          },
                        },
                        [_vm._v("同步至企微标签")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.upDateTag(row)
                            },
                          },
                        },
                        [_vm._v(" 更新 ")]
                      ),
                      _vm._v(" "),
                      _vm.tagType == 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.copyTag(row)
                                },
                              },
                            },
                            [_vm._v("\n            复制\n          ")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      [null, undefined].includes(row.refCount) ||
                      row.refCount <= 0
                        ? _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "permission",
                                  rawName: "v-permission",
                                  value: ["labelManagement:edit"],
                                  expression: "['labelManagement:edit']",
                                },
                              ],
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.editLabel(row)
                                },
                              },
                            },
                            [_vm._v("编辑")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-dropdown",
                        {
                          directives: [
                            {
                              name: "permission",
                              rawName: "v-permission",
                              value: [
                                "labelManagement:open",
                                "labelManagement:detail",
                                "labelManagement:del",
                              ],
                              expression:
                                "[\n              'labelManagement:open',\n              'labelManagement:detail',\n              'labelManagement:del',\n            ]",
                            },
                          ],
                          staticStyle: { "margin-left": "10px" },
                          attrs: { trigger: "click" },
                        },
                        [
                          _c("el-button", { attrs: { type: "text" } }, [
                            _vm._v("更多"),
                            _c("i", {
                              staticClass: "el-icon-arrow-down el-icon--right",
                            }),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-dropdown-menu",
                            { attrs: { slot: "dropdown" }, slot: "dropdown" },
                            [
                              _c(
                                "el-dropdown-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value: ["labelManagement:open"],
                                          expression:
                                            "['labelManagement:open']",
                                        },
                                      ],
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.changeStatus(row)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          row.status == 0 ? "禁用" : "启用"
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-item",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value: ["labelManagement:detail"],
                                          expression:
                                            "['labelManagement:detail']",
                                        },
                                      ],
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.labelDetail(row)
                                        },
                                      },
                                    },
                                    [_vm._v("详情")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-dropdown-item",
                                [
                                  _vm.tagType == 0
                                    ? _c(
                                        "el-button",
                                        {
                                          directives: [
                                            {
                                              name: "permission",
                                              rawName: "v-permission",
                                              value: ["labelManagement:del"],
                                              expression:
                                                "['labelManagement:del']",
                                            },
                                          ],
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.labelDel(row)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pagebox" },
              [
                _c("el-pagination", {
                  attrs: {
                    "current-page": _vm.params.page,
                    "page-sizes": [10, 20, 30, 40],
                    "page-size": _vm.params.pageSize,
                    layout: "total, sizes, prev, pager, next, jumper",
                    total: _vm.total,
                    background: "",
                  },
                  on: {
                    "size-change": _vm.handleSizeChange,
                    "current-change": _vm.handleCurrentChange,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.type == 1 ? "添加分组" : "编辑分组",
            width: "360px",
            visible: _vm.isshowAdd,
            "before-close": _vm.handelClose,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.isshowAdd = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "ruleFormRef", attrs: { model: _vm.ruleForm } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入标签分组，15个字符内",
                      maxlength: "15",
                    },
                    model: {
                      value: _vm.ruleForm.groupName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "groupName", $$v)
                      },
                      expression: "ruleForm.groupName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.handelClose } }, [
                _vm._v("取 消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitGroup } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "同步至企微标签",
            visible: _vm.sycWechatTagDialog,
            width: "800px",
            top: "10vh",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.sycWechatTagDialog = $event
            },
            close: function ($event) {
              _vm.sycWechatTagParam.corpIdList = null
              _vm.sycWechatTagParam.wechatUserList = null
              _vm.sycWechatTagParam.wechatUserType = 2
              _vm.sendUserList = []
              _vm.sycWechatTagDialog = false
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "sycWechatTagRefs",
                  attrs: {
                    model: _vm.sycWechatTagParam,
                    rules: _vm.sycWechatTagRule,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "企微成员", prop: "wechatUserType" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: {
                            change: function ($event) {
                              _vm.sycWechatTagParam.wechatUserList = null
                            },
                          },
                          model: {
                            value: _vm.sycWechatTagParam.wechatUserType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.sycWechatTagParam,
                                "wechatUserType",
                                $$v
                              )
                            },
                            expression: "sycWechatTagParam.wechatUserType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("所有主体成员"),
                          ]),
                          _vm._v(" "),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _c("span", [_vm._v("自定义成员")]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    _vm.sycWechatTagParam.wechatUserType === 2,
                                  expression:
                                    "sycWechatTagParam.wechatUserType === 2",
                                },
                              ],
                              attrs: { type: "text" },
                              on: { click: _vm.addUser },
                            },
                            [_vm._v("添加")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.sycWechatTagParam.wechatUserType === 2,
                              expression:
                                "sycWechatTagParam.wechatUserType === 2",
                            },
                          ],
                        },
                        [
                          _vm._v(
                            "已选择" +
                              _vm._s(_vm.sendUserList.length) +
                              "个企微"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.sycWechatTagParam.corpIdList = null
                      _vm.sycWechatTagParam.wechatUserList = null
                      _vm.sycWechatTagParam.wechatUserType = 2
                      _vm.sendUserList = []
                      _vm.sycWechatTagDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.sycWechatTagSubmit },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c("selectCreateWay", {
        ref: "selectCreateWayRef",
        on: { selectData: _vm.selectData },
      }),
      _vm._v(" "),
      _c("SelectUser", {
        ref: "selectUserRef",
        on: { selectUser: _vm.selectUserData },
      }),
      _vm._v(" "),
      _c("importUser", {
        ref: "importUserRef",
        on: {
          handleSuccess: function ($event) {
            _vm.params.page = 1
            _vm.getList()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }